import { createSlice } from '@reduxjs/toolkit';
import { api } from 'utils/functions/api';
import { UP_ENGINE } from 'config';

const initialState = {};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
});

// export const {  } = auth.actions;
//
export default auth.reducer;

export const sendOtp = (payload) =>
  api.post(UP_ENGINE, '/api/auth/send-otp', payload);

export const verifyOtp = (payload) =>
  api.post(UP_ENGINE, '/api/auth/verify-otp', payload);
