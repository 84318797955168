import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import upff from '../../assets/images/upff_logo.svg';
import gaithertv from '../../assets/images/gaither_tv_plus_logo.svg';
import minno from '../../assets/images/minno_logo.svg';

const OtherLogoWrapper = ({ displaySubLogo, children }) => {
  return (
    <>
      <div className="m-auto d-flex flex-column align-items-center page-width vh-100">
        <Header displaySubLogo={displaySubLogo} />
        <div className="flex-grow-1 w-100">{children}</div>
        <footer className="m-3">
          <div className="text-center roboto font16px">
            © {new Date().getFullYear()} UP Entertainment, LLC.
          </div>

          <div className="d-block d-sm-none">
            <div className="d-flex justify-content-center flex-column m-3 gap-4">
              <img src={upff} alt="logo" />
              <img src={gaithertv} alt="logo" />
              <img src={minno} alt="logo" />
            </div>
          </div>
          <div className="d-none d-sm-block">
            <div className="d-flex justify-content-center m-3 gap-4">
              <img src={upff} width="100" alt="logo" />
              <img src={gaithertv} width="100" alt="logo" />
              <img src={minno} width="100" alt="logo" />
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default OtherLogoWrapper;
