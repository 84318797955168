import { combineReducers } from '@reduxjs/toolkit';
import accountDetails from './account';
import auth from './auth';
import cart from './cart';
import snackbar from './snackbar';
import loader from './loader';
import alert from './alert';
import chargebee from './chargebee';
import paymentSourceDetails from './paymentSource';
import modal from './modal';

const rootReducer = combineReducers({
  auth,
  cart,
  snackbar,
  billingDetails: accountDetails,
  loader,
  alert,
  modal,
  chargebee,
  paymentSourceDetails,
});

export default rootReducer;
