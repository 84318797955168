import { api } from 'utils/functions/api';
import { UP_ENGINE } from '../config';

export const communicationPreferences = () => () =>
  api
    .get(UP_ENGINE, '/api/account/communicationPreferences')
    .then((response) => {
      return response;
    });

export const updateCommunicationPreferences = (payload) => () =>
  api
    .post(UP_ENGINE, '/api/account/communicationPreferences', payload)
    .then((response) => {
      return response;
    });
