import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = () => {
  return (
    <LinearProgress
      sx={{
        backgroundColor: '#fff0c1',
        zIndex: 9999,
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#F9971F',
        },
      }}
    />
  );
};

export default ProgressBar;
