import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

import upff from './../../assets/images/upff_logo.svg';
import gaithertv from './../../assets/images/gaither_tv_plus_logo.svg';
import minno from './../../assets/images/minno_logo.svg';
import { useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { fetchCartDetails, membershipChangeEstimate } from '../../redux/cart';
import { hideGlobalLoader, showGlobalLoader } from '../../redux/loader';
import {
  PERIOD_UNIT,
  SUBSCRIPTION_ACTION,
  SUBSCRIPTION_STATUS,
} from '../../utils/constant';
import OtherLogoWrapper from '../../components/LogoWrapper/other';
import { getPlanImage } from '../../utils/functions';

function Summary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [cartItems, setCartItems] = useState([]);
  const [data, setData] = useState({});
  const [showLayout, setShowLayout] = useState(false);

  useEffect(() => {
    loadPlanDetails();
  }, []);

  const checkIsDowngraded = (id) => {
    const item = cartItems.find(
      (i) => i.id === id && i.status === SUBSCRIPTION_STATUS.NON_RENEWING
    );
    const itemCart = cartItems.find((i) => i.id === id);

    if (item?.cancelAt) {
      return ` Your access to ${item.name} will end at the end of your billing cycle, ${item.cancelAt} .`;
    } else if (itemCart?.hasUpcomingCoupon) {
      return 'Coupon will be applied on your next billing cycle';
    } else {
      return '';
    }
  };

  const isMonthlyPlan = () => {
    return cartItems[0]?.periodUnit === PERIOD_UNIT.MONTH;
  };

  const loadPlanDetails = () => {
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');
    dispatch(showGlobalLoader());
    dispatch(
      action === SUBSCRIPTION_ACTION.DOWNGRADE
        ? membershipChangeEstimate(action)
        : fetchCartDetails()
    )
      .then((response) => {
        let { existingPlans } = response;
        let allItems = [
          ...existingPlans.map((plan) => {
            return {
              ...plan,
              subscribed: true,
              checked: plan.status !== SUBSCRIPTION_STATUS.NON_RENEWING,
            };
          }),
        ];

        setShowLayout(true);
        setCartItems(allItems);
        setData(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(hideGlobalLoader());
      });
  };

  const getSubscribedItems = () => {
    return cartItems.filter(
      (item) =>
        item?.subscribed === true &&
        item.status !== SUBSCRIPTION_STATUS.CANCELLED
    );
  };

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  const startStreaming = (url) => {
    window.open(url, '_blank');
  };

  const getButtonComponent = (planId) => {
    if (planId === 'UP-Faith-Family') {
      return (
        <div
          className="text-center font16px roboto mt-3 stream-btn"
          style={{
            height: '60px',
            backgroundColor: '#F9971F',
            borderRadius: '6px',
            color: '#ffffff',
            cursor: 'pointer',
          }}
        >
          <p
            className="p-2"
            onClick={() =>
              startStreaming('https://my.upfaithandfamily.com/browse')
            }
          >
            START STREAMING UP
            <br /> FAITH & FAMILY
          </p>
        </div>
      );
    } else if (planId === 'GaitherTV') {
      return (
        <div
          className="text-center font16px roboto mt-3 stream-btn"
          style={{
            height: '60px',
            backgroundColor: '#2B8CB3',
            borderRadius: '6px',
            color: '#ffffff',
            cursor: 'pointer',
          }}
        >
          <p
            className="p-2"
            onClick={() =>
              startStreaming('https://my.gaithertvplus.com/browse')
            }
          >
            START STREAMING
            <br /> GAITHERTV+
          </p>
        </div>
      );
    } else if (planId === 'Minno-Plan') {
      return (
        <div
          className="text-center font16px roboto mt-3 stream-btn"
          style={{
            height: '60px',
            backgroundColor: '#3BBFBE',
            borderRadius: '6px',
            color: '#ffffff',
            cursor: 'pointer',
          }}
        >
          <p
            className="p-2"
            onClick={() => startStreaming('https://kids.gominno.com/welcome')}
          >
            START STREAMING
            <br /> MINNO
          </p>
        </div>
      );
    }
  };

  return (
    <OtherLogoWrapper displaySubLogo={false}>
      {showLayout && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="my-3">
                <div
                  className="fw-bold font14px cursor-pointer color-blue roboto"
                  onClick={handleManageAccount}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp; Manage Your Account
                </div>
              </div>

              <div className="text-center text-sm-start">
                <h3 className="mt-4 mb-4 kranto-bold">Manage Subscriptions</h3>
                <div className="row my-2">
                  <div className="container container-border p-3 bg-white">
                    <div className="d-flex justify-content-between mb-3 mx-2 my-2">
                      <h4 className="kranto-bold">Your Subscriptions</h4>
                    </div>

                    <div className="container">
                      <table className="table table_custom">
                        <thead>
                          <tr style={{ borderStyle: 'hidden' }}>
                            <th scope="col" width="90%"></th>
                            <th scope="col" width="30%"></th>
                            {/*<th scope="col" width="40%"></th>*/}
                          </tr>
                        </thead>
                        <tbody>
                          {getSubscribedItems().map((plan, index) => {
                            return (
                              <tr style={{ height: '70px' }}>
                                <td>
                                  <div className="d-flex justify-content-between flex-custom-align gap-3">
                                    <div>
                                      <img
                                        src={getPlanImage(plan.id)}
                                        // width={120}
                                        alt={plan.name}
                                      />
                                    </div>
                                    <div className="roboto font15px custom-text-align">
                                      {plan.checked && <b>{plan?.name}</b>}
                                    </div>
                                  </div>
                                  <p className="color-red mt-2 font14px roboto">
                                    {checkIsDowngraded(plan.id)}
                                  </p>
                                </td>

                                <td className="text-end roboto">
                                  {plan.checked && <b>{`$${plan?.amount}`}</b>}
                                </td>
                              </tr>
                            );
                          })}

                          {data.couponDiscount && (
                            <tr style={{ height: '70px' }}>
                              {/*<td scope="row"></td>*/}
                              <td className="custom-text-align roboto">
                                <b className="color-red">Coupon Code</b>
                              </td>
                              <td className="text-end roboto">
                                <b className="color-red">{`- $${data.couponDiscount}`}</b>
                              </td>
                            </tr>
                          )}

                          {data.bundleSavings && (
                            <tr style={{ height: '70px' }}>
                              {/*<td scope="row"></td>*/}
                              <td className="custom-text-align roboto">
                                <b className="color-red">Bundle Savings</b>
                              </td>
                              <td className="text-end roboto">
                                <b className="color-red">{`- $${data.bundleSavings}`}</b>
                              </td>
                            </tr>
                          )}

                          {data.total && (
                            <tr style={{ height: '70px' }}>
                              {/*<td scope="row"></td>*/}
                              <td className="custom-text-align">
                                <p className="roboto font12px">
                                  <b className="roboto font15px">
                                    {`Total ${isMonthlyPlan() ? 'Monthly' : 'Yearly'}  Costs`}
                                  </b>{' '}
                                  <br /> and applicable taxes{' '}
                                </p>
                              </td>
                              <td className="fw-bold text-end">{`$${data.total}`}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row">
                      <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                        {getSubscribedItems().map((plan, index) => {
                          return getButtonComponent(plan.id);
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </OtherLogoWrapper>
  );
}

export default Summary;
