import styled from 'styled-components';

export const OuterContainer = styled.div`
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
`;

export const PreferencesHeader = styled.h3`
  font-family: 'kranto-text-cond-bold';
  margin: 10px 0;
  margin-left: 0px;
`;

export const PreferencesContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const PreferencesDescription = styled.p.attrs({
  className: 'font16px roboto',
})`
  font-size: 14px;
  text-align: left;
  margin-bottom: 20px;
  margin-left: 10px;
`;

export const PreferencesTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  height: 70px;
  border-bottom: 1px solid #eee;
`;

export const TableCell = styled.td`
  padding: 10px;
`;

export const ToggleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SaveButton = styled.button`
  background-color: #2b8cb3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  &:disabled {
    background-color: #ccc;
  }
`;

export const PreferencesHeading = styled.h4`
  font-family: 'kranto-text-cond-bold'; /* Use the same font family as PreferencesHeader for consistency */
  margin-left: 10px; /* Align to the left side of the white box */
  margin-bottom: 10px; /* Add some spacing between the heading and description */
  text-align: left; /* Ensure it is left-aligned */
`;

export const NavLink = styled.a`
  font-weight: 700;
  font-size: 14px;
  color: #20a0c4;
  font-family: Roboto, serif;
`;

// Modal Styles
export const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
`;

export const ModalHeader = styled.div`
  padding: 15px;
  border-bottom: none; /* Remove default border */
  display: flex;
  justify-content: center;
`;

export const ModalTitle = styled.h5`
  font-size: 24px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
`;

export const ModalBody = styled.div`
  padding: 20px;
  text-align: center;
`;

export const ModalText = styled.p`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
`;

export const WarningAlert = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff3cd;
  color: #856404;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
`;

export const WarningIcon = styled.div`
  margin-right: 10px;
`;

export const ModalFooter = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  border-top: none; /* Remove default border */
`;

export const UnsubscribeButton = styled.button`
  background-color: #ffffff;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
`;

export const CancelButton = styled.button`
  background-color: #ffa500; /* Solid orange color */
  color: white;
  border: none; /* No outer border */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0; /* Sharp rectangular corners */
  cursor: pointer;
`;
