import { createSlice } from '@reduxjs/toolkit';
import { api } from 'utils/functions/api';
import { UP_ENGINE } from '../config';

const initialState = {
  isCbInitiated: false,
  cbInstance: '',
  enableCancelButton: false,
};

export const chargebee = createSlice({
  name: 'chargebee',
  initialState,
  reducers: {
    loadChargebee: (state, action) => {
      let { siteName, publishableKey, enableCancelButton } = action.payload;
      state.cbInstance = window.Chargebee.init({
        site: siteName,
        iframeOnly: true,
        publishableKey: publishableKey,
      });
      state.isCbInitiated = true;
      state.enableCancelButton = enableCancelButton;
    },
  },
});

export const initChargebee = () => async (dispatch) => {
  // dispatch(loadChargebee());
  const response = await api.get(UP_ENGINE, '/api/site');
  dispatch(loadChargebee(response));
  return response;
};

export const { loadChargebee } = chargebee.actions;

export default chargebee.reducer;
