import styled from 'styled-components';

export const Heading = styled.div`
  font-weight: 700;
  font-size: 28px;
  font-family: kranto-text-cond-bold, serif;
`;

export const SubHeading = styled.div`
  font-weight: 700;
  font-size: 22px;
  font-family: kranto-text-cond-bold, serif;
`;

export const NavLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: #20a0c4;
`;

export const Badge = styled.button`
  height: 19px;
  border-radius: 5px;
  border: 0;
  opacity: 0px;
  color: #111111;
  background: #dedede;
  font-size: 10px;
  font-weight: 400;
  opacity: 43%;
`;

export const CardDetail = styled.div`
  color: #212529;
  font-size: 16px;
  font-weight: 500px;
`;
