import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
  OuterContainer,
  PreferencesContainer,
  PreferencesHeader,
  PreferencesDescription,
  PreferencesHeading,
  PreferencesTable,
  TableRow,
  TableCell,
  ToggleButtonWrapper,
  SaveButton,
  NavLink,
} from './style';
import { ToggleButton } from '../../components/ToggleButton';
import OtherLogoWrapper from 'components/LogoWrapper/other';
import ActionAlerts from '../../components/Alert';
import { ButtonLoader } from '../../components/Loader';
import { hideGlobalLoader, showGlobalLoader } from '../../redux/loader';
import {
  communicationPreferences,
  updateCommunicationPreferences,
} from '../../redux/emailPreferencesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../redux/snackbar';
import ModalPopUp from '../../components/Modal';
import { hideModal, showModal } from '../../redux/modal';
import { fetchAccountDetails } from '../../redux/account';

function CommunicationPreferences() {
  const { email } = useSelector((state) => state.billingDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    preferences();
    if (!email) {
      dispatch(fetchAccountDetails());
    }
    return () => {
      dispatch(hideModal());
    };
  }, []);

  const [showLayout, setShowLayout] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [emailPreferences, setEmailPreferences] = useState([
    {
      key: 'newsLetter',
      checked: false,
      name: 'UP Faith & Family Weekly Newsletter',
      summary:
        'Stay in the loop with our Weekly newsletter—a curated collection of new episodes and movie titles delivered to your inbox.',
    },
    {
      key: 'streaming',
      checked: false,
      name: "What's Streaming",
      summary:
        'Get our Coming UP monthly newsletter, new season announcement emails for series favorites like Heartland, early access to exclusive movie titles, and much more!',
    },
    {
      key: 'promotions',
      checked: false,
      name: 'Marketing & Promotional Emails',
      summary:
        'Be the first to know about exciting events & exclusive subscriber promotions.',
    },
    {
      key: 'branding',
      checked: false,
      name: 'UP Entertainment Brands',
      summary:
        'Explore beyond UP Faith & Family and discover more uplifting content on our other platforms—GaitherTV+ and AspireTV+.',
    },
  ]);

  const preferences = async () => {
    await dispatch(showGlobalLoader());
    return await dispatch(communicationPreferences())
      .then((response) => {
        console.log('Response:', response);
        const updatedPreferences = emailPreferences.map((preference) => {
          return {
            ...preference,
            checked: response[preference.key],
          };
        });
        setEmailPreferences(updatedPreferences);
        setShowLayout(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(hideGlobalLoader());
      });
  };

  const updatePreferences = (ev) => {
    const { id, checked } = ev.target;
    const updatedPreferences = emailPreferences.map((item) => {
      if (item.key === id) return { ...item, checked };
      return item;
    });
    setEmailPreferences(updatedPreferences);
  };

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  const createPayload = () => {
    const payload = {
      newsLetter: false,
      streaming: false,
      promotions: false,
      branding: false,
    };
    emailPreferences.forEach((item) => {
      if (item.checked) {
        payload[item.key] = true;
      }
    });
    return payload;
  };

  const handleSaveChange = () => {
    const payload = createPayload();
    setProcessing(true);
    dispatch(updateCommunicationPreferences(payload))
      .then(() => {
        const selectedPreferences = emailPreferences.filter(
          (item) => item.checked
        );
        setEmailPreferences(selectedPreferences);
        navigate('/emailConfirm');
      })
      .catch((err) => {
        dispatch(showSnackbar({ message: err.message, severity: 'error' }));
      })
      .finally(() => setProcessing(false));
  };

  const disableAllPreferences = () => {
    const payload = {
      newsLetter: false,
      streaming: false,
      promotions: false,
      branding: false,
    };
    setProcessing(true);
    dispatch(updateCommunicationPreferences(payload))
      .then(() => {
        navigate('/emailConfirm?state=unsubscribeAll');
      })
      .catch((err) => {
        dispatch(showSnackbar({ message: err.message, severity: 'error' }));
      })
      .finally(() => setProcessing(false));
  };

  const openModal = () => {
    dispatch(
      showModal({
        title: `Unsubscribe From All`,
        message: `Are you sure you want to unsubscribe from all emails?`,
        warningContent: `By unsubscribing from emails, you won’t be able to access  special deals or notifications on upcoming series.`,
        modalData: {},
        confirmBtnText: `YES, UNSUBSCRIBE`,
      })
    );
  };
  const closeModal = () => {
    dispatch(hideModal());
  };

  return (
    <>
      <OtherLogoWrapper displaySubLogo={false}>
        {showLayout && (
          <OuterContainer>
            <div
              className="fw-bold font14px cursor-pointer color-blue roboto"
              onClick={handleManageAccount}
            >
              <FontAwesomeIcon size="lg" icon={faArrowLeft} />
              &nbsp; Manage Your Account
            </div>
            <PreferencesHeader>Manage Email Preferences</PreferencesHeader>
            <ActionAlerts />
            <PreferencesContainer>
              <PreferencesHeading>Preferences</PreferencesHeading>
              <PreferencesDescription>
                <a href={`mailto:${email}`}>{email}</a>&nbsp; has been
                subscribed to the following communications from UP
                Entertainment.
              </PreferencesDescription>
              <PreferencesTable>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style={{ border: 'hidden' }}>
                  {emailPreferences.map((plan, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <ToggleButtonWrapper>
                          <ToggleButton
                            id={plan.key}
                            checked={plan.checked}
                            onChange={updatePreferences}
                          />
                        </ToggleButtonWrapper>
                      </TableCell>
                      <TableCell>
                        <div>
                          <b>{plan?.name}</b>
                          <div>{plan?.summary}</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </PreferencesTable>
            </PreferencesContainer>
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
              <SaveButton onClick={handleSaveChange} disabled={processing}>
                {processing ? <ButtonLoader /> : 'SAVE PREFERENCES'}
              </SaveButton>
            </div>

            <div className="text-center mt-4 font16px fw-bold roboto">
              {emailPreferences.some((preference) => preference.checked) && (
                <div>
                  <NavLink href="#" onClick={openModal}>
                    Unsubscribe From All
                  </NavLink>
                </div>
              )}
            </div>
          </OuterContainer>
        )}
      </OtherLogoWrapper>
      <ModalPopUp modalClose={closeModal} modalSave={disableAllPreferences} />
    </>
  );
}

export default CommunicationPreferences;
