import axios from 'axios';

import { deepTransformKeys } from './object';
import { toCamelCase, toSnakeCase } from './text';
import ls from './localstore';

const axiosClient = axios.create();

const setupInterceptors = (navigate) => {
  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      // If the response has a status code of 401 (Unauthorized)
      if (error.response && error.response.status === 401) {
        // Clear authentication tokens
        ls.remove('token');
        // Redirect the user to the login page
        navigate('/');
      }
      // Return a rejected promise to allow handling in calling code
      return Promise.reject(error);
    }
  );
};

const request = (method, service, url, opts) => {
  const payload = {
    baseURL: service,
    headers: {
      // Authorization: `Bearer ${ls.get('token')}`
    },
    method,
    url,
    ...opts,
  };

  const token = ls.get('token');
  if (token != null) payload['headers']['Authorization'] = `Bearer ${token}`;

  return axiosClient
    .request(payload)
    .then((response) => {
      const { data } = response;
      // const { status, data } = response;

      return deepTransformKeys(data, toCamelCase);
    })
    .catch((error) => {
      const { config, response } = error;
      if (response) {
        const { status, data } = response;
        console.warn(
          `${config.method} call to "${config.url}" resulted in ${status} (${data.code})`
        );

        const throwable = { ...data, status };
        throw throwable;
      } else {
        console.log(`Error: ${error.message}`);
        const throwable = {
          status: -1,
          code: '-1',
          message: error.message,
          backtrace: error.stack,
        };
        throw throwable;
      }
    });
};

const api = {
  get(service, url, data = {}) {
    return request('GET', service, url, {
      params: deepTransformKeys(data, toCamelCase),
    });
  },
  post(service, url, data = {}) {
    return request('POST', service, url, {
      data: deepTransformKeys(data, toCamelCase),
    });
  },
  put(service, url, data = {}) {
    return request('PUT', service, url, {
      data: deepTransformKeys(data, toCamelCase),
    });
  },
  delete(service, url, data = {}) {
    return request('DELETE', service, url, {
      params: deepTransformKeys(data, toSnakeCase),
    });
  },
};

export { api, setupInterceptors };

export const plugin = ({ baseUrl, method, path, params }) =>
  api[method](baseUrl, path, params);
