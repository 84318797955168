import ModalPopUp from 'components/Modal';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { showModal, hideModal } from '../../../redux/modal';
import { pauseEstimate, pauseSubscription } from '../../../redux/account';
import { showSnackbar } from '../../../redux/snackbar';
import { useNavigate } from 'react-router-dom';

export const Btn = styled.button`
  font-weight: 500;
  font-size: 16x;
  background: #f9971f;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
`;

const Pause = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resumeDate, setResumeDate] = useState({});

  useEffect(() => {
    getResumeDate(30);
    getResumeDate(60);

    return () => {
      dispatch(hideModal());
    };
  }, []);

  const openModal = () => {
    dispatch(
      showModal({
        title: `Pause Your Subscription?`,
        message: `Are you sure you want to pause your subscriptions?`,
        warningContent: `Your subscription is paused until ${resumeDate[pauseDays()]}. You will not be billed during this period.

You will not have access to premium content during the pause.`,
        confirmBtnText: `YES, PAUSE SUBSCRIPTIONS`,
      })
    );
  };

  const getResumeDate = (days) => {
    if (!resumeDate[days]) {
      pauseEstimate(days)
        .then((res) => {
          resumeDate[days] = res?.resumeDate;
        })
        .catch((err) => {});
    }
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  const handlePause = () => {
    const days = pauseDays();

    pauseSubscription(days)
      .then((res) => {
        navigate('/dashboard');
      })
      .catch((err) => {
        dispatch(showSnackbar({ message: err?.message, severity: 'error' }));
      })
      .finally(() => {});
  };

  const pauseDays = () => {
    return document.querySelector('.form-select').value;
  };

  return (
    <>
      <div className=" my-2">
        <div className="container border-orange p-3 bg-white">
          <div
            className="d-flex flex-column align-items-center flex-nowrap"
            style={{ gap: '10px' }}
          >
            <h5 className="text-orange font22px roboto fw-bold">
              Wont you stay a little longer?
            </h5>

            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
              <div className="">
                <select class="form-select" aria-label="Default select example">
                  <option value="30" defaultChecked>
                    30 days
                  </option>
                  <option value="60">60 days</option>
                </select>
              </div>
              <Btn
                type="button"
                className="btn btn-orange font16px"
                onClick={openModal}
              >
                Pause Subscription
              </Btn>
            </div>
          </div>
        </div>
      </div>

      <ModalPopUp modalClose={closeModal} modalSave={handlePause} />
    </>
  );
};

export default Pause;
