import { createSlice } from '@reduxjs/toolkit';
import { api } from 'utils/functions/api';
import { UP_ENGINE } from '../config';
import { PERIOD_UNIT } from 'utils/constant';

const initialState = {
  planLoaded: false,
  items: [],
  bundleSavings: 0,
  total: 0,
  initialPlans: [],
  isYealyPlan: false,
};

export const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItem: (state, action) => {
      let { existingPlans, plans, bundleSavings, total, periodUnit } =
        action.payload;
      state.planLoaded = true;
      state.bundleSavings = bundleSavings;
      state.total = total;
      state.isYealyPlan = periodUnit === PERIOD_UNIT.YEAR;
      state.items = [
        ...existingPlans.map((existingPlan) => {
          return {
            ...existingPlan,
            subscribed: true,
            checked: true,
          };
        }),
        ...plans.map((plan) => {
          return {
            ...plan,
            subscribed: false,
            checked: false,
          };
        }),
      ];
      state.initialPlans = existingPlans.map((plan) => plan.id);
    },
    updateEstimate: (state, action) => {
      let { bundleSavings, total } = action.payload;
      state.bundleSavings = bundleSavings;
      state.total = total;
    },
  },
});

export const { setCartItem, updateEstimate } = cart.actions;

export default cart.reducer;

export const fetchCartDetails = () => () =>
  api.get(UP_ENGINE, '/api/account/manage-plans').then((response) => {
    return response;
  });

export const retrieveEstimate = (payload) => () =>
  api
    .post(UP_ENGINE, '/api/account/add-remove-service/estimate', payload)
    .then((response) => {
      return response;
    });

export const membershipChangeEstimate = (action) => () =>
  api.get(UP_ENGINE, `/api/account/${action}/estimate`).then((response) => {
    return response;
  });

export const updateSubscription = (action) => () =>
  api.post(UP_ENGINE, `/api/account/${action}`).then((response) => {
    return response;
  });

export const cancelAllSubscription = (payload) => () =>
  api.post(UP_ENGINE, `/api/account/cancel`, payload).then((response) => {
    return response;
  });

export const addOrRemoveService = (payload) => (dispatch) =>
  api
    .post(UP_ENGINE, '/api/account/add-remove-service', payload)
    .then((response) => {
      dispatch(setCartItem(response));
      return response;
    });
export const updateCoupon = (coupon) => () =>
  api.post(UP_ENGINE, `/api/account/add-coupon/${coupon}`).then((response) => {
    return response;
  });

export const retrieveResumeEstimate = () => () =>
  api.get(UP_ENGINE, '/api/account/resume/estimate').then((response) => {
    return response;
  });

export const retrieveClaimEstimate = () =>
  api.get(UP_ENGINE, '/api/account/claim-my-offer/estimate');
