import React from 'react';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const TooltipUPTV = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
    color: black;
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    font-size: 16px;
    width: 250px;
    font-family: Roboto,sans-serif;
    box-shadow: 0px 4px 4px 0px #00000021;
    border-radius: 13px;
    padding: 12px;
`);

const TooltipX = ({ children, message }) => {
  return (
    <TooltipUPTV title={message} placement="top">
      {children}
    </TooltipUPTV>
  );
};

export default TooltipX;
