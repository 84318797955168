import React, { useState, useEffect, useRef } from 'react';
import { Heading } from './style';
import { useNavigate } from 'react-router-dom';
import { initChargebee, loadChargebee } from '../../redux/chargebee';
import { useDispatch, useSelector } from 'react-redux';
import { addCardPaymentSources } from '../../redux/paymentSource';
import SnackbarAlert from 'components/Snackbar';
import { showSnackbar } from '../../redux/snackbar';
import OtherLogoWrapper from 'components/LogoWrapper/other';
import { getpaymentToken } from '../../redux/paymentSource';
import { deepTransformKeys } from 'utils/functions/object';
import { toSnakeCase } from 'utils/functions/text';
import { showGlobalLoader, hideGlobalLoader } from './../../redux/loader';
import { useLocation } from 'react-router-dom';

function AddPaypalPaymentMethod() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { cbInstance, isCbInitiated } = useSelector((state) => state.chargebee);
  const operationType = location?.state?.operationType;
  const paymentSourceId = location?.state?.paymentSourceId;

  useEffect(() => {
    dispatch(showGlobalLoader());
    if (!isCbInitiated) {
      dispatch(initChargebee());
    }
  }, []);

  useEffect(() => {
    if (cbInstance) initExpressCheckout();
  }, [cbInstance]);

  const handleManageAccount = () => {
    navigate('/dashboard');
  };
  const cardRef = useRef(null);

  const initExpressCheckout = () => {
    cbInstance.load('paypal').then((paypalHandler) => {
      createPaymentIntent().then((intent) => {
        delay(1000).then(() => {
          paypalHandler.setPaymentIntent(intent);

          return paypalHandler
            .mountPaymentButton('#paypal-button-container', {
              style: getPaypalStyles(),
            })
            .then(() => {
              dispatch(hideGlobalLoader());
              return paypalHandler.handlePayment({
                success: (paymentIntent) => {
                  // express.setIntent(paymentIntent);
                },
                error: (err) => {
                  console.log(err);
                },
              });
            })
            .then((paymentIntent) => {
              console.log('paymentIntent', paymentIntent);

              dispatch(
                addCardPaymentSources({
                  token: intent.id,
                  operationType: operationType,
                  paymentsource: 'PAYPAL',
                  paymentSourceId,
                })
              )
                .then((response) => {
                  console.log(response);
                  navigate('/add-payment-method-success', {
                    state: { type: 'paypal' },
                  });
                })
                .catch((error) => {
                  dispatch(
                    showSnackbar({
                      message: error?.error_msg,
                      severity: 'error',
                    })
                  );
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });
      });
    });
  };

  const createPaymentIntent = async () => {
    let payment_intent = await getpaymentToken();
    return deepTransformKeys(payment_intent, toSnakeCase);
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getPaypalStyles = () => {
    return {
      layout: 'vertical',
      size: 'responsive',
    };
  };

  return (
    <>
      <OtherLogoWrapper displaySubLogo={false}>
        <div className="p-3">
          <div className="pm-layout">
            <div onClick={handleManageAccount}>
              <b>
                <a className="link-opacity-80 text-decoration-none" href="#">
                  <span className="font17px text-center align-content-center">
                    &#8592;
                  </span>{' '}
                  Manage Your Account
                </a>
              </b>
            </div>

            <div className="">
              <div>
                <Heading className="text-center text-sm-start mt-3 mb-2">
                  {operationType === 'ADD'
                    ? 'Add Payment Method'
                    : 'Update Payment Method'}
                </Heading>
              </div>

              <div className="my-4">
                <div>
                  <form className="d-flex justify-content-center">
                    <div id="paypal-button-container"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherLogoWrapper>
    </>
  );
}

export default AddPaypalPaymentMethod;
