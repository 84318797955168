import React from 'react';
import { Heading, SubHeading } from './style';
import { useNavigate } from 'react-router-dom';
import pm1 from './../../assets/images/payment-method1.svg';
import pm2 from './../../assets/images/payment-method2.svg';
import paypal from './../../assets/images/paypal.svg';
import OtherLogoWrapper from 'components/LogoWrapper/other';
import { useDispatch } from 'react-redux';
import { showSnackbar } from './../../redux/snackbar';

function AddPaymentMethod() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  const handleAddPaymentMethod = () => {
    const checkedRadio = document.querySelector(
      'input[name="paymentMethod"]:checked'
    );
    if (checkedRadio) {
      const value = checkedRadio.value;
      if (value === 'card') navigate('/add-card-payment-method');
      else if (value === 'paypal') {
        navigate('/add-paypal-payment-method', {
          state: { operationType: 'ADD' },
        });
      }
    } else {
      dispatch(
        showSnackbar({
          message: 'Choose payment method to continue',
          severity: 'error',
        })
      );
    }
  };

  return (
    <>
      <OtherLogoWrapper displaySubLogo={false}>
        <div className="">
          <div className="mx-3">
            <div onClick={handleManageAccount}>
              <b>
                <a className="link-opacity-80 text-decoration-none" href="#">
                  <span className="font17px text-center align-content-center">
                    &#8592;
                  </span>{' '}
                  Manage Your Account
                </a>
              </b>
            </div>

            <div className="">
              <div>
                <Heading className="text-center text-sm-start mt-3 mb-2">
                  Add Payment Method
                </Heading>
              </div>

              <div className="my-4">
                <div className="container container-border p-3 bg-white">
                  <SubHeading>Choose Payment Methods</SubHeading>

                  <div
                    className="d-flex flex-column m-3"
                    style={{ gap: '10px' }}
                  >
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ gap: '25px' }}
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="paymentMethod"
                          id="paymentCard"
                          value="card"
                        />
                        <label
                          class="form-check-label kranto-bold font20px fw-bold"
                          for="paymentCard"
                        >
                          Card
                        </label>
                      </div>

                      <div
                        className="d-flex flex-row flex-wrap"
                        style={{ gap: '15px' }}
                      >
                        <img src={pm1} width="250px" />
                        <img src={pm2} width="250px" />
                      </div>
                    </div>

                    <hr></hr>

                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ gap: '25px' }}
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="paymentMethod"
                          id="paymentPayPal"
                          value="paypal"
                        />
                        <label
                          class="form-check-label kranto-bold font20px fw-bold"
                          for="paymentPayPal"
                        >
                          PayPal
                        </label>
                      </div>

                      <div className="d-flex flex-row" style={{ gap: '15px' }}>
                        <img src={paypal} width="64px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center mt-5">
                {/* <button className="add-pm-btn" onClick={handleAddPaymentMethod}>
                  Continue
                </button> */}

                <button
                  onClick={handleAddPaymentMethod}
                  type="button"
                  className="btn btn-primary px-5"
                  // disabled={processing}
                  // style={processing ? disable : active}
                  style={{ backgroundColor: '#2B8CB3' }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </OtherLogoWrapper>
    </>
  );
}

export default AddPaymentMethod;
