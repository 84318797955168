import styled from 'styled-components';

export const Heading = styled.div`
  font-size: 2rem;
`;

export const SubHeading = styled.div.attrs({
  className: 'roboto',
})`
  font-weight: 400;
  font-size: 20px;
`;

export const Btn = styled.button`
  background-color: #2b8cb3;
  color: #ffffff;
  font-weight: bolder;
  border: 2px solid #2b8cb3;
  border-radius: 5px;
  padding: 1vh 6vw 1vh 6vw;
  font-size: 14px;
  font-family: filson-pro-bold, sans-serif;
  text-transform: none;
  width: 100%;
`;
export const ResendOtp = styled.p.attrs({
  className: 'mt-3 font16px mb-2 roboto',
})`
  color: #20a0c4;
  font-weight: 700;
  cursor: pointer;
`;

export const disable = {
  opacity: 0.5,
  backgroundColor: '#2B8CB3',
  color: '#ffffff',
  fontFamily: 'Roboto',
  fontSize: '20px',
};
export const active = {
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontWeight: '400',
};
