function get(key) {
  const value = sessionStorage.getItem(key) || localStorage.getItem(key);
  return value || null;
}

function set(key, value, permanent = true) {
  const storageLocation = permanent ? localStorage : sessionStorage;
  storageLocation.setItem(key, value);
  return value;
}

function remove(key) {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
}

const localStore = {
  get,
  set,
  remove,
};

export default localStore;
