import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
  OuterContainer,
  PreferencesContainer,
  PreferencesHeader,
  PreferencesDescription,
  PreferencesTable,
  TableRow,
  TableCell,
  SaveButton,
} from '../style';
import OtherLogoWrapper from 'components/LogoWrapper/other';
import ActionAlerts from '../../../components/Alert';
import { hideGlobalLoader, showGlobalLoader } from '../../../redux/loader';
import { communicationPreferences } from '../../../redux/emailPreferencesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountDetails } from '../../../redux/account';

function ConfirmPreferences() {
  const { email } = useSelector((state) => state.billingDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    preferences();
    if (!email) {
      dispatch(fetchAccountDetails());
    }
    setIsUnsubscribeAll(queryParams.get('state') === 'unsubscribeAll');
  }, []);

  const [showLayout, setShowLayout] = useState(false);
  const [isUnsubscribeAll, setIsUnsubscribeAll] = useState(false);
  const [emailPreferences, setEmailPreferences] = useState([
    {
      key: 'newsLetter',
      checked: false,
      name: 'UP Faith & Family Weekly Newsletter',
      summary:
        'Stay in the loop with our Weekly newsletter—a curated collection of new episodes and movie titles delivered to your inbox.',
    },
    {
      key: 'streaming',
      checked: false,
      name: "What's Streaming",
      summary:
        'Get our Coming UP monthly newsletter, new season announcement emails for series favorites like Heartland, early access to exclusive movie titles, and much more!',
    },
    {
      key: 'promotions',
      checked: false,
      name: 'Marketing & Promotional Emails',
      summary:
        'Be the first to know about exciting events & exclusive subscriber promotions.',
    },
    {
      key: 'branding',
      checked: false,
      name: 'UP Entertainment Brands',
      summary:
        'Explore beyond UP Faith & Family and discover more uplifting content on our other platforms—GaitherTV+ and AspireTV+.',
    },
  ]);

  const preferences = async () => {
    await dispatch(showGlobalLoader());
    return await dispatch(communicationPreferences())
      .then((response) => {
        console.log('Response:', response);
        const updatedPreferences = emailPreferences.map((preference) => {
          return {
            ...preference,
            checked: response[preference.key],
          };
        });
        const selectedPreferences = updatedPreferences.filter(
          (item) => item.checked
        );
        setEmailPreferences(selectedPreferences);
        setShowLayout(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(hideGlobalLoader());
      });
  };

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  return (
    <OtherLogoWrapper displaySubLogo={false}>
      {showLayout && (
        <OuterContainer>
          <div
            className="fw-bold font14px cursor-pointer color-blue roboto"
            onClick={handleManageAccount}
          >
            <FontAwesomeIcon size="lg" icon={faArrowLeft} />
            &nbsp; Manage Your Account
          </div>
          <PreferencesHeader>Your Email Preferences</PreferencesHeader>
          <PreferencesContainer>
            <PreferencesDescription>
              <a href={`mailto:${email}`}>{email}</a>&nbsp; has been subscribed
              to the following communications from UP Entertainment.
              {isUnsubscribeAll && (
                <p className="fw-bold roboto font16px mt-3">
                  PLEASE ALLOW 10 (ten) business days for your request to be
                  processed
                </p>
              )}
            </PreferencesDescription>
            <ActionAlerts />
            {emailPreferences.length > 0 && (
              <PreferencesTable>
                <tbody style={{ border: 'hidden' }}>
                  {emailPreferences.map((plan, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <div>
                          <b>{plan?.name}</b>
                          <div>{plan?.summary}</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </PreferencesTable>
            )}
          </PreferencesContainer>

          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <SaveButton onClick={handleManageAccount}>
              BACK TO ACCOUNT PORTAL
            </SaveButton>
          </div>
        </OuterContainer>
      )}
    </OtherLogoWrapper>
  );
}

export default ConfirmPreferences;
