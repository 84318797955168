import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  title: '',
  message: '',
  warningContent: '',
  modalData: {},
  confirmBtnText: '',
};

export const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action) => {
      const { title, message, warningContent, modalData, confirmBtnText } =
        action.payload;
      state.open = true;
      state.title = title;
      state.message = message;
      state.warningContent = warningContent;
      state.modalData = modalData;
      state.confirmBtnText = confirmBtnText;
    },
    hideModal: (state) => {
      state.open = false;
      state.title = '';
      state.message = '';
      state.warningContent = '';
      state.modalData = {};
    },
    modalReset: () => {
      return initialState;
    },
  },
});

export const { showModal, hideModal, modalReset } = modal.actions;

export default modal.reducer;
