import React from 'react';
import upff from './../../assets/images/upff_logo.svg';
import gaithertv from './../../assets/images/gaither_tv_plus_logo.svg';
import minno from './../../assets/images/minno_logo.svg';

const Footer = () => {
  return (
    <footer className="m-3">
      <div className="d-block d-sm-none">
        <div className="d-flex justify-content-center m-3 gap-4">
          <img src={upff} width="100" alt="logo" />
          <img src={gaithertv} width="100" alt="logo" />
          <img src={minno} width="100" alt="logo" />
        </div>
      </div>
      <div className="text-center">
        © {new Date().getFullYear()} UP Entertainment, LLC.
      </div>
    </footer>
  );
};

export default Footer;
