export const MAX_OTP_ATTEMPTS = 5;

export const UP_SUPPORT_MESSAGE =
  'Something went wrong, If you are continuing to have problems. ' +
  'Please contact support.';

export const PERIOD_UNIT = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
};

export const SEVERITY = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const SUBSCRIPTION_STATUS = {
  IN_TRIAL: 'IN_TRIAL',
  ACTIVE: 'ACTIVE',
  NON_RENEWING: 'NON_RENEWING',
  CANCELLED: 'CANCELLED',
  PAUSED: 'PAUSED',
};

export const SUBSCRIPTION_ACTION = {
  UPGRADE: 'upgrade',
  DOWNGRADE: 'downgrade',
  DOWNGRADE_WITH_TRIAL: 'downgrade_with_trial',
};

export const MODAL_TYPE = {
  ADJUST_SUBSCRIPTION: 'ADJUST_SUBSCRIPTION',
  REMOVE_SERVICE: 'REMOVE_SERVICE',
};
