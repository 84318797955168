import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from '../../redux/snackbar';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function SnackbarAlert() {
  const { visible, content, severity } = useSelector((state) => state.snackbar);

  const [state] = React.useState({
    vertical: 'bottom',
    horizontal: 'center',
    timeout: 10000,
  });

  const { vertical, horizontal, timeout } = state;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      autoHideDuration={timeout}
      anchorOrigin={{ vertical, horizontal }}
      open={visible}
      onClose={handleClose}
    >
      <MuiAlert
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        elevation={6}
        variant="filled"
        severity={severity}
      >
        {content}
      </MuiAlert>
    </Snackbar>
  );
}
