import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from '../../redux/alert';
import { active, disable } from '../../pages/login/style';
import { ButtonLoader } from '../Loader';
import React from 'react';

function ActionAlerts(props) {
  const { open, message, callback } = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(hideAlert());

  return (
    <>
      {open && (
        <Alert severity="info" onClose={handleClose} className="mt-2">
          {props?.message ?? message}
          <br />
          {(props.callback || callback) && (
            <button
              onClick={props.callback ?? callback}
              type="button"
              disabled={props.processing}
              className="btn btn-primary px-3 mt-1 font14px"
              style={props.processing ? disable : active}
              style={{
                backgroundColor: '#2B8CB3',
                border: '1px solid #2B8CB3',
              }}
            >
              {props.processing ? (
                <ButtonLoader />
              ) : (
                (props?.btnText ?? 'SAVE CHANGES')
              )}
            </button>
          )}
        </Alert>
      )}
    </>
  );
}

export default ActionAlerts;
