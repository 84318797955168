import { useSelector } from 'react-redux';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa';

export const UnsubscribeButton = styled.button`
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  border: 2px solid;
  cursor: pointer;
  font-weight: 550;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

export const CancelButton = styled.button`
  background-color: #f9971f;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px; /* Sharp rectangular corners */
  cursor: pointer;
`;

const ModalPopUp = (props) => {
  const { open, title, message, warningContent, data, confirmBtnText } =
    useSelector((state) => state.modal);

  const handleClose = () => props.modalClose();
  const handleSave = () => props.modalSave();

  return (
    <Modal
      size="lg"
      show={open}
      onHide={handleClose}
      backdrop="static"
      centered
    >
      <div className="container">
        <div className="text-center mt-4 mb-2">
          <h3 className="kranto-bold">{title}</h3>
        </div>
        <Modal.Body>
          <p className="roboto fw-bold">{message}</p>
          {warningContent && (
            <div className="d-flex my-2">
              <div style={{ width: '10px', background: '#FA703F' }}></div>
              <div className="p-2" style={{ background: '#FFE9D9' }}>
                <div
                  className="font20px"
                  style={{
                    fontFamily: 'kranto-text-cond-bold',
                    color: '#771505',
                  }}
                >
                  <div>
                    <FaExclamationTriangle />
                    &nbsp; Warning
                  </div>
                </div>
                <div
                  className="roboto font16px my-2"
                  style={{ color: '#BC4C2E' }}
                >
                  {warningContent}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: 0 }}>
          <div className="d-flex justify-content-between w-100 mb-3">
            <CancelButton type="button" onClick={handleClose}>
              NO, CANCEL
            </CancelButton>
            <UnsubscribeButton type="button" onClick={handleSave}>
              {confirmBtnText ?? 'YES, DOWNGRADE'}
            </UnsubscribeButton>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ModalPopUp;
