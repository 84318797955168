import React, { useEffect, useState } from 'react';
import { Badge, Heading, SubHeading } from './style';
// import visa from './../../assets/images/visa.svg';
// import mastercard from './../../assets/images/mastercard.svg';
import edit from './../../assets/images/edit.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCardPaymentSources,
  fetchPaymentSources,
} from './../../redux/paymentSource';
import { initChargebee, loadChargebee } from './../../redux/chargebee';
// import paypalSVG from './../../assets/images/paypal.svg';
import deleteSVG from './../../assets/images/delete.svg';
import warningSVG from './../../assets/images/warning.svg';
import OtherLogoWrapper from 'components/LogoWrapper/other';
import { ToggleButton } from 'components/ToggleButton';
import { showGlobalLoader, hideGlobalLoader } from './../../redux/loader';
import { showSnackbar } from './../../redux/snackbar';
import TooltipX from 'components/Tooltip';
import { hideModal, showModal } from './../../redux/modal';
import ModalPopUp from 'components/Modal';
import visa from './../../assets/images/cards/visalogo.png';
import dinersclub from './../../assets/images/cards/dinersclublogo.png';
import discover from './../../assets/images/cards/discoverlogo.png';
import jcb from './../../assets/images/cards/jcblogo.png';
import mastercard from './../../assets/images/cards/mastercardlogo.png';
import amex from './../../assets/images/cards/amexlogo.png';
import paypallogo from './../../assets/images/cards/paypallogo.png';

function ManagePaymentMethod() {
  const [showLayout, setShowLayout] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [primaryPaymentSourceId, setPrimaryPaymentSourceId] = useState(null);
  const { isCbInitiated } = useSelector((state) => state.chargebee);
  const { modalData } = useSelector((state) => state.modal);
  const { card, paypal } = useSelector((state) => state.paymentSourceDetails);

  useEffect(() => {
    dispatch(showGlobalLoader());
    dispatch(fetchPaymentSources()).then((response) => {
      setShowLayout(true);
      dispatch(hideGlobalLoader());
      let { card, paypal } = response;
      let card_ = [...card, ...paypal];
      card_.forEach((c) => {
        if (c?.primaryPaymentSource) {
          setPrimaryPaymentSourceId(c?.paymentSourceId);
        }
      });
    });
    if (!isCbInitiated) {
      dispatch(initChargebee());
    }
  }, []);

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  const handleAddPaymentMethod = () => {
    navigate('/add-payment-method');
  };

  const handleUpdateCardPaymentMethod = (card) => {
    navigate('/update-card-payment-method', {
      state: {
        ...card,
      },
    });
  };

  const handleDeletePaymentMethod = (paymentSourceId) => {
    dispatch(showGlobalLoader());
    dispatch(
      addCardPaymentSources({ paymentSourceId, operationType: 'DELETE' })
    )
      .then((res) => {
        dispatch(hideGlobalLoader());
        closeModal();
      })
      .catch((err) => {
        dispatch(hideGlobalLoader());
        closeModal();
        dispatch(showSnackbar({ message: err?.message, severity: 'error' }));
      });
  };

  const getCardBrandImg = (brand) => {
    if (brand === 'MASTERCARD') return mastercard;
    else if (brand === 'VISA') return visa;
    else if (brand === 'JCB') return jcb;
    else if (brand === 'DISCOVER') return discover;
    else if (brand === 'DINERS_CLUB') return dinersclub;
    else if (brand === 'AMERICAN_EXPRESS') return amex;
  };

  const updatePrimaryCard = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setPrimaryPaymentSourceId(id);
      dispatch(showGlobalLoader());
      dispatch(
        addCardPaymentSources({
          paymentSourceId: id,
          operationType: 'SETPRIMARY',
        })
      )
        .then((res) => {
          dispatch(hideGlobalLoader());
        })
        .catch((err) => {
          dispatch(hideGlobalLoader());
        });
    }
  };

  const isDeletableCard = (c) => {
    return c?.deletable;
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  const openModal = (id) => {
    dispatch(
      showModal({
        title: `Delete Payment Method`,
        message: `Are you sure you want to delete your payment method?`,
        warningContent: `This payment method is not set as your default. Removing it will not affect your active subscription. You can continue to use your default payment method for future payments.`,
        confirmBtnText: `YES, REMOVE`,
        modalData: {
          id,
        },
      })
    );
  };

  return (
    <>
      <OtherLogoWrapper displaySubLogo={false}>
        {showLayout && (
          <div className="">
            <div className="mx-3">
              <div onClick={handleManageAccount}>
                <b>
                  <a className="link-opacity-80 text-decoration-none" href="#">
                    <span className="font17px text-center align-content-center">
                      &#8592;
                    </span>{' '}
                    Manage Your Account
                  </a>
                </b>
              </div>

              <div className="">
                <div>
                  <Heading className="text-center text-sm-start mt-3 mb-2">
                    Manage Payment Method
                  </Heading>
                </div>

                <div className="my-4">
                  <div className="container container-border p-3 bg-white">
                    <SubHeading>Your Payment Methods</SubHeading>

                    <div className="d-flex flex-column" style={{ gap: '10px' }}>
                      {card.map((c, i) => {
                        return (
                          <>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <div
                                  className="d-flex flex-column flex-sm-row align-items-center"
                                  style={{ gap: '5px' }}
                                >
                                  <ToggleButton
                                    id={c?.paymentSourceId}
                                    checked={
                                      c?.paymentSourceId ===
                                      primaryPaymentSourceId
                                    }
                                    onChange={updatePrimaryCard}
                                  />
                                  {c?.primaryPaymentSource && (
                                    <>
                                      {c?.deletable ? (
                                        <Badge>Default</Badge>
                                      ) : (
                                        <TooltipX message="Your default payment method can’t be deleted becanuse you have an active plan.">
                                          <Badge>Default</Badge>
                                        </TooltipX>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ gap: '10px' }}
                                >
                                  <div>
                                    <img
                                      src={getCardBrandImg(c?.brand)}
                                      className="card-icon"
                                    />
                                  </div>
                                  <div className="text-end card-detail roboto">
                                    <div>{c?.maskedNumber}</div>
                                    <div>{`${c?.firstName ?? ''} ${c?.lastName ?? ''}`}</div>
                                    <div>{`${c?.expiryMonth}/${c?.expiryYear}`}</div>
                                  </div>
                                  <div>
                                    <img
                                      src={edit}
                                      className="edit-pm-btn"
                                      onClick={() =>
                                        handleUpdateCardPaymentMethod(c)
                                      }
                                    />
                                  </div>

                                  <div className="">
                                    <img
                                      src={deleteSVG}
                                      className="delete-pm-btn"
                                      onClick={() => {
                                        if (isDeletableCard(c))
                                          openModal(c?.paymentSourceId);
                                      }}
                                      data-bs-toggle={
                                        c?.primaryPaymentSource && !c?.deletable
                                          ? 'modal'
                                          : ''
                                      }
                                      data-bs-target={
                                        c?.primaryPaymentSource && !c?.deletable
                                          ? '#delete-pri-pm'
                                          : ''
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {i < card.length + paypal.length - 1 && <hr />}
                          </>
                        );
                      })}

                      {paypal.map((c, i) => {
                        return (
                          <>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <div
                                  className="d-flex flex-column flex-sm-row align-items-center"
                                  style={{ gap: '5px' }}
                                >
                                  <ToggleButton
                                    id={c?.paymentSourceId}
                                    checked={
                                      c?.paymentSourceId ===
                                      primaryPaymentSourceId
                                    }
                                    onChange={updatePrimaryCard}
                                  />

                                  {c?.primaryPaymentSource && (
                                    <>
                                      <TooltipX message="Your default payment method can’t be deleted becanuse you have an active plan.">
                                        <Badge>Default</Badge>
                                      </TooltipX>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ gap: '10px' }}
                                >
                                  <div>
                                    <img
                                      src={paypallogo}
                                      className="card-icon"
                                    />
                                  </div>
                                  <div className="text-end card-detail roboto">
                                    <div className="w-s-120">{c?.email}</div>
                                    {/* <div>{c?.paymentSourceId}</div> */}
                                  </div>
                                  <div>
                                    <img
                                      src={edit}
                                      className="edit-pm-btn"
                                      // style={{ cursor: 'not-allowed' }}
                                      onClick={() =>
                                        navigate('/add-paypal-payment-method', {
                                          state: {
                                            operationType: 'UPDATE',
                                            paymentSourceId: c?.paymentSourceId,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={deleteSVG}
                                      className="delete-pm-btn"
                                      onClick={() => {
                                        if (c?.deletable)
                                          openModal(c?.paymentSourceId);
                                      }}
                                      data-bs-toggle={
                                        c?.primaryPaymentSource && !c?.deletable
                                          ? 'modal'
                                          : ''
                                      }
                                      data-bs-target={
                                        c?.primaryPaymentSource && !c?.deletable
                                          ? '#delete-pri-pm'
                                          : ''
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {i < paypal.length - 1 && <hr />}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="font14px mt-2 roboto">
                  <div
                    className="d-flex flex-row flex-wrap align-items-center justify-content-center"
                    style={{ gap: '10px' }}
                  >
                    <div className="flex-shrink-0">We accept</div>

                    <div
                      className="d-flex flex-row flex-wrap"
                      style={{ gap: '10px' }}
                    >
                      <img src={mastercard} height="30px" width="auto" />
                      <img src={visa} height="30px" width="auto" />
                      <img src={amex} height="30px" width="auto" />
                      <img src={discover} height="30px" width="auto" />
                      <img src={jcb} height="30px" width="auto" />
                      <img src={dinersclub} height="30px" width="auto" />
                      <img src={paypallogo} height="30px" width="auto" />
                    </div>
                  </div>
                </div>

                <div className="text-center mt-5">
                  {/* <button className="add-pm-btn" onClick={handleAddPaymentMethod}>
                  ADD NEW PAYMENT METHOD
                </button> */}
                  <button
                    onClick={handleAddPaymentMethod}
                    type="button"
                    className="btn btn-primary px-5 "
                    // disabled={processing}
                    // style={processing ? disable : active}
                    style={{ backgroundColor: '#2B8CB3' }}
                  >
                    ADD NEW PAYMENT METHOD
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </OtherLogoWrapper>

      <ModalPopUp
        modalClose={closeModal}
        modalSave={() => handleDeletePaymentMethod(modalData.id)}
      />

      <div className="">
        <div
          class="modal fade"
          id="delete-pri-pm"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-3">
              <div class="modal-header border-0"></div>
              <div class="modal-body">
                <div className="d-flex">
                  <div style={{ width: '10px', background: '#FA703F' }}></div>
                  <div className="p-3" style={{ background: '#FFE9D9' }}>
                    <div
                      className="font20px"
                      style={{
                        fontFamily: 'kranto-text-cond-bold',
                        color: '#771505',
                      }}
                    >
                      <div className="d-flex flex-col" style={{ gap: '10px' }}>
                        <img src={warningSVG} />
                        Warning
                      </div>
                    </div>
                    <div className="roboto" style={{ color: '#000000' }}>
                      Your default payment method can’t be deleted because you
                      have an active plan.
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button
                  type="button"
                  class="btn-dismiss"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagePaymentMethod;
