import React, { useRef } from 'react';
import { Heading } from './style';
import { useNavigate } from 'react-router-dom';
import CBCardComponent from '../../components/cardComponent';
import { useDispatch } from 'react-redux';
import OtherLogoWrapper from 'components/LogoWrapper/other';
import FailedPaymentAlert from 'components/Alert/FailedPayment';

function UpdateCardPaymentMethod() {
  const navigate = useNavigate();

  const handleManageAccount = () => {
    navigate('/dashboard');
  };
  const cardRef = useRef(null);

  return (
    <>
      <OtherLogoWrapper displaySubLogo={false}>
        <div className="p-3">
          <div className="pm-layout">
            <div onClick={handleManageAccount}>
              <b>
                <a className="link-opacity-80 text-decoration-none" href="#">
                  <span className="font17px text-center align-content-center">
                    &#8592;
                  </span>{' '}
                  Manage Your Account
                </a>
              </b>
            </div>

            <div className="">
              <div>
                <Heading className="text-center text-sm-start mt-3 mb-2">
                  {/* <FailedPaymentAlert
                    handleSubmit={cardRef?.current?.handlePM}
                  /> */}
                  Update Payment Method
                </Heading>
              </div>

              <div className="my-4">
                <CBCardComponent ref={cardRef} operationType="UPDATE" />
              </div>
            </div>
          </div>
        </div>
      </OtherLogoWrapper>
    </>
  );
}

export default UpdateCardPaymentMethod;
