import React, { useState, useEffect, useRef } from 'react';
import { Heading } from './style';
import { useNavigate } from 'react-router-dom';
import CBCardComponent from '../../components/cardComponent';
import { loadChargebee } from '../../redux/chargebee';
import { useDispatch } from 'react-redux';
import { addCardPaymentSources } from '../../redux/paymentSource';
import { useLocation } from 'react-router-dom';
import OtherLogoWrapper from 'components/LogoWrapper/other';
// import mastercard from './../../assets/images/mastercard.svg';
// import visa from './../../assets/images/visa.svg';
import visa from './../../assets/images/cards/visalogo.png';
import dinersclub from './../../assets/images/cards/dinersclublogo.png';
import discover from './../../assets/images/cards/discoverlogo.png';
import jcb from './../../assets/images/cards/jcblogo.png';
import mastercard from './../../assets/images/cards/mastercardlogo.png';
import amex from './../../assets/images/cards/amexlogo.png';

function AddPaymentMethodSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const card = location.state?.card;
  const type = data?.type;
  const operationType = location?.state?.operationType;

  console.log(data);

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  const getAccountType = () => {
    const type = data?.type;
    if (type) return type.charAt(0).toUpperCase() + type.slice(1);
    return '';
  };

  const getCardBrandImg = (brand) => {
    if (brand === 'MASTERCARD') return mastercard;
    else if (brand === 'VISA') return visa;
    else if (brand === 'JCB') return jcb;
    else if (brand === 'DISCOVER') return discover;
    else if (brand === 'DINERS_CLUB') return dinersclub;
    else if (brand === 'AMERICAN_EXPRESS') return amex;
  };

  return (
    <>
      <OtherLogoWrapper displaySubLogo={false}>
        <div className="p-3">
          <div className="pm-layout mw600px">
            <div onClick={handleManageAccount}>
              <b>
                <a className="link-opacity-80 text-decoration-none" href="#">
                  <span className="font17px text-center align-content-center">
                    &#8592;
                  </span>{' '}
                  Manage Your Account
                </a>
              </b>
            </div>

            <div className="">
              <div>
                <Heading className="text-center text-sm-start mt-3 mb-2">
                  Add Payment Method
                </Heading>
              </div>

              <div className="my-4">
                {type === 'paypal' && (
                  <div className="add-pm-success kranto-bold font22px fw-bold d-flex justify-content-center align-items-center">
                    Successfully Added PayPal Account
                  </div>
                )}

                {type === 'card' && (
                  <div
                    className="add-pm-success d-flex flex-column justify-content-center"
                    style={{ gap: '15px' }}
                  >
                    <div className="kranto-bold font22px fw-bold">
                      Card Information Added
                    </div>
                    {card && (
                      <div className="d-flex flex-row" style={{ gap: '15px' }}>
                        <div>
                          <img
                            src={getCardBrandImg(card?.brand)}
                            className="card-icon"
                          />
                        </div>
                        <div className="text-start card-detail roboto">
                          <div>{card?.maskedNumber}</div>
                          <div>{`${card?.firstName ?? ''} ${card?.lastName ?? ''}`}</div>
                          <div>{`${card?.expiryMonth}/${card?.expiryYear}`}</div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="mt-5 text-center">
                  <button
                    type="button"
                    className="btn btn-primary px-5 "
                    style={{ backgroundColor: '#2B8CB3' }}
                    onClick={handleManageAccount}
                  >
                    BACK TO ACCOUNT PORTAL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherLogoWrapper>
    </>
  );
}

export default AddPaymentMethodSuccess;
