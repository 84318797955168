import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'routes/index';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'mediaQuery/mobile.css';
import 'mediaQuery/tablet.css';
import 'mediaQuery/window.css';
import './index.css';
import './App.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

persistor.subscribe(() => {
  const { bootstrapped } = persistor.getState();
  if (bootstrapped) {
    // Rehydration is complete
    // Load your app or perform any necessary actions
    root.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
