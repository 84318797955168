import { PERIOD_UNIT } from '../constant';
import upff from '../../assets/images/upff_logo.svg';
import gaithertv from '../../assets/images/gaither_tv_plus_logo.svg';
import minno from '../../assets/images/minno_logo.svg';

export const getRemainingDays = (dateString) => {
  const targetDate = new Date(dateString);
  const currentDate = new Date();

  const differenceInMilliseconds = targetDate - currentDate;

  return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
};

export const billingPeriodText = (billingPeriod) => {
  switch (billingPeriod) {
    case PERIOD_UNIT.YEAR:
      return 'annually';
    case PERIOD_UNIT.MONTH:
      return 'monthly';
    default:
      return billingPeriod;
  }
};

export const getPlanImage = (id) => {
  switch (id) {
    case 'UP-Faith-Family':
      return upff;
    case 'GaitherTV':
      return gaithertv;
    case 'Minno-Plan':
    case 'Minno':
      return minno;
  }
};
