import { createSlice } from '@reduxjs/toolkit';
import { UP_ENGINE } from 'config';
import { api } from 'utils/functions/api';
import { hideErrorAlert, showErrorAlert } from './alert';

const initialState = {
  name: '',
  email: '',
  plans: [],
  isMonthlyPlan: false,
  hasPaymentFailure: false,
  hashasScheduledPause: false,
  hasPauseSub: false,
};

export const accountDetails = createSlice({
  name: 'accountDetails',
  initialState,
  reducers: {
    setAccountDetails: (state, action) => {
      let billingDetails = action.payload;
      state.email = billingDetails?.email;
      state.plans = billingDetails?.plans;
      state.name = billingDetails?.name;
      state.isMonthlyPlan =
        billingDetails?.plans &&
        billingDetails?.plans[0]?.billingPeriod === 'MONTH';
      state.hasPauseSub = billingDetails?.hasPauseSub;
      state.hasScheduledPause = billingDetails?.hasScheduledPause;
    },
    setPaymentFailure: (state, action) => {
      state.hasPaymentFailure = action.payload?.hasPaymentFailure;
    },
  },
});

export const { setAccountDetails, setPaymentFailure } = accountDetails.actions;

export default accountDetails.reducer;

export const fetchAccountDetails = () => (dispatch) =>
  api.get(UP_ENGINE, '/api/account').then((response) => {
    dispatch(setAccountDetails(response));
    return response;
  });

export const checkPaymentFailure = () => (dispatch) => {
  api.get(UP_ENGINE, '/api/account/check-payment-failure').then((response) => {
    dispatch(
      setPaymentFailure({ hasPaymentFailure: response?.hasPaymentFailure })
    );

    if (response?.hasPaymentFailure) {
      dispatch(showErrorAlert());
    } else {
      dispatch(hideErrorAlert());
    }

    return response;
  });
};

export const pauseSubscription = (days) =>
  api.post(UP_ENGINE, `/api/account/pause?days=${days}`).then((response) => {
    return response;
  });

export const pauseEstimate = (days) =>
  api.get(UP_ENGINE, `/api/account/pause/estimate?days=${days}`);

export const resumeSubscription = () => (dispatch) =>
  api.post(UP_ENGINE, '/api/account/resume').then((response) => {
    dispatch(setAccountDetails(response));
    return response;
  });

export const removeScheduledPause = () =>
  api.post(UP_ENGINE, '/api/account/remove-scheduled-pause');

export const claimMyOffer = () => (dispatch) =>
  api.post(UP_ENGINE, '/api/account/claim-my-offer').then((response) => {
    dispatch(setAccountDetails(response));
    return response;
  });
