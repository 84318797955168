import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButton } from '../../components/ToggleButton';

import upff from './../../assets/images/upff_logo.svg';
import gaithertv from './../../assets/images/gaither_tv_plus_logo.svg';
import minno from './../../assets/images/minno_logo.svg';
import { useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { membershipChangeEstimate, updateSubscription } from '../../redux/cart';
import { hideGlobalLoader, showGlobalLoader } from '../../redux/loader';
import { showSnackbar } from '../../redux/snackbar';
import { ButtonLoader } from '../../components/Loader';
import { active, disable } from '../login/style';
import {
  PERIOD_UNIT,
  SEVERITY,
  SUBSCRIPTION_ACTION,
  SUBSCRIPTION_STATUS,
} from '../../utils/constant';
import ActionAlerts from '../../components/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import OtherLogoWrapper from '../../components/LogoWrapper/other';
import { hideAlert, showAlert } from '../../redux/alert';
import { showErrorAlert } from '../../redux/alert';
import FailedPaymentAlert from 'components/Alert/FailedPayment';
import { getPlanImage } from '../../utils/functions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  // height: '30%',
  width: '60%',
  p: 4,
};

function AdjustSubscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [showLayout, setShowLayout] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [duplicateCart, setDuplicateCart] = useState([]);

  const isMonthlyPlan = () => {
    const items = getSubscribedItems();
    return items[0]?.periodUnit === PERIOD_UNIT.MONTH;
  };
  const displaySnackbar = (message, severity) =>
    dispatch(showSnackbar({ message, severity }));

  useEffect(() => {
    const action = location.pathname.split('/').pop();
    loadCartDetails(action);
    return undefined;
  }, []);

  const loadCartDetails = async (action) => {
    await dispatch(showGlobalLoader());
    return await dispatch(membershipChangeEstimate(action))
      .then((response) => {
        let { existingPlans, credits } = response;
        let formattedItems = [
          ...existingPlans.map((existingPlan) => {
            return {
              ...existingPlan,
              subscribed: true,
              checked: existingPlan.status !== SUBSCRIPTION_STATUS.NON_RENEWING,
            };
          }),
        ];

        if (action === SUBSCRIPTION_ACTION.UPGRADE) {
          // Hide Alert banner if credits is $0
          if (credits && parseInt(credits) > 0) {
            dispatch(showAlert(constructBannerMessage(response)));
          }
        }
        setData(response);
        setCartItems(formattedItems);
        setShowLayout(true);
      })
      .catch((error) => {
        console.log(error);
        displaySnackbar(error.message, SEVERITY.ERROR);
      })
      .finally(() => {
        dispatch(hideGlobalLoader());
      });
  };

  const getSubscribedItems = () => {
    return cartItems.filter(
      (item) => item?.subscribed === true || item.checked === true
    );
  };

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  const handleSaveChange = () => {
    setProcessing(true);
    let action = location.pathname.split('/').pop();
    dispatch(updateSubscription(action))
      .then((response) => {
        if (response?.isImmediate) {
          action = SUBSCRIPTION_ACTION.DOWNGRADE_WITH_TRIAL;
        }
        navigate(`/summary?action=${action}`);
      })
      .catch((err) => {
        console.log(err);
        dispatch(showSnackbar({ message: err.message, severity: 'error' }));
        checkPaymentFailure(err);
      })
      .finally(() => setProcessing(false));
  };

  const checkPaymentFailure = (err) => {
    if (err?.errorCode === 'payment_processing_failed') {
      dispatch(showErrorAlert());
    }
  };

  const checkIsDowngraded = (id) => {
    const item = duplicateCart.find(
      (i) => i.id === id && i.status === SUBSCRIPTION_STATUS.NON_RENEWING
    );
    return item?.cancelAt
      ? ` Your access to ${item.name} will end at the end of your billing cycle, ${item.cancelAt} .`
      : '';
  };

  const constructBannerMessage = (response) => {
    const { existingPlans, total, credits } = response;
    const planList = existingPlans
      .filter((p) => !p.subId)
      .map((plan) => plan.name);
    const planName = planList.join(', ');
    return `You are upgrading to an annual subscription of ${planName}. You will immediately be charged $${total} for your annual subscription less a credit of $${credits} for any days remaining on your current monthly subscription.`;
  };

  return (
    <OtherLogoWrapper displaySubLogo={false}>
      {showLayout && (
        <div className="container">
          <Modal
            open={false}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                align="center"
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Remove plan_name
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to remove plan_name?
              </Typography>

              <div className="d-flex justify-content-between my-3">
                <Button sx={{ backgroundColor: 'orange' }} variant="contained">
                  NO,CANCEL
                </Button>
                <Button variant="outlined">YES,DOWNGRADE</Button>
              </div>
            </Box>
          </Modal>
          <div className="row">
            <div className="col-12">
              <div className="my-3">
                <div
                  className="fw-bold font14px cursor-pointer color-blue roboto"
                  onClick={handleManageAccount}
                >
                  <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                  &nbsp; Manage Your Account
                </div>
              </div>
              <div className="text-center text-sm-start">
                <h3 className="mt-4 mb-4 kranto-bold">Manage Subscriptions</h3>
              </div>
              <ActionAlerts
                callback={handleSaveChange}
                processing={processing}
              />
              <FailedPaymentAlert />
              <div className="container">
                <div className="row my-2">
                  <div className="container container-border p-3 bg-white">
                    <div className="d-flex justify-content-between mb-3 mx-2 my-2">
                      <h4 className="kranto-bold">Your Subscriptions</h4>
                    </div>
                    <div className="container">
                      <table className="table table_custom">
                        <thead className="table-header">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {getSubscribedItems().map((plan, index) => {
                            return (
                              <tr style={{ height: '70px' }}>
                                <td scope="row">
                                  <ToggleButton
                                    id={plan.id}
                                    checked={plan.checked}
                                  />
                                </td>
                                <td>
                                  <div className="d-flex justify-content-between flex-wrap gap-3">
                                    <div>
                                      <img
                                        src={getPlanImage(plan.id)}
                                        // width={120}
                                        alt={plan.name}
                                      />
                                    </div>
                                    <div className="roboto font15px">
                                      {plan.checked && <b>{plan?.name}</b>}
                                    </div>
                                  </div>
                                  <p className="color-red mt-2 font14px roboto">
                                    {checkIsDowngraded(plan.id)}
                                  </p>
                                </td>
                                <td className="text-end roboto">
                                  {plan.checked && <b>{`$${plan.amount}`}</b>}
                                </td>
                              </tr>
                            );
                          })}

                          {data.annualSavings && (
                            <tr style={{ height: '70px' }}>
                              <td scope="row"></td>
                              <td
                                className="custom-text-align roboto"
                                style={{ color: '#9A0909' }}
                              >
                                <b className="font15px">{`You save $${data.annualSavings} in annual cost`}</b>
                              </td>
                              <td
                                className="text-end roboto"
                                style={{ color: '#9A0909' }}
                              >
                                <b>{`- $${data.annualSavings}`}</b>
                              </td>
                            </tr>
                          )}

                          {data.bundleSavings && (
                            <tr style={{ height: '70px' }}>
                              <td scope="row"></td>
                              <td
                                className="custom-text-align roboto"
                                style={{ color: '#9A0909' }}
                              >
                                <b className="font15px">Bundle Savings</b>
                              </td>
                              <td
                                className="text-end roboto"
                                style={{ color: '#9A0909' }}
                              >
                                <b>{`- $${data.bundleSavings}`}</b>
                              </td>
                            </tr>
                          )}
                          {data.total && (
                            <tr style={{ height: '70px' }}>
                              <td scope="row"></td>
                              <td className="custom-text-align">
                                <p className="roboto font12px">
                                  <b className="roboto font15px">
                                    {`Total ${isMonthlyPlan() ? 'Monthly' : 'Yearly'}  Costs`}
                                  </b>{' '}
                                  <br /> and applicable taxes{' '}
                                </p>
                              </td>
                              <td className="fw-bold text-end">{`$${data.total}`}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-3 text-center" style={{ margin: 'auto' }}>
                    <button
                      onClick={handleSaveChange}
                      type="button"
                      className="btn btn-primary px-5 "
                      disabled={processing}
                      style={processing ? disable : active}
                      style={{ backgroundColor: '#2B8CB3' }}
                    >
                      {processing ? <ButtonLoader /> : 'SAVE CHANGES'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </OtherLogoWrapper>
  );
}

export default AdjustSubscription;
