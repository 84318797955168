import OtherLogoWrapper from '../../components/LogoWrapper/other';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Label } from './style';
import { active, disable } from '../login/style';
import { ButtonLoader } from '../../components/Loader';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../redux/snackbar';
import { updateCoupon } from '../../redux/cart';
import { SEVERITY } from '../../utils/constant';

const UpdateCoupon = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [code, setCode] = useState('');

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  const handleSave = () => {
    if (code) {
      setProcessing(true);
      dispatch(updateCoupon(code))
        .then(() => {
          navigate('/summary');
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            showSnackbar({ message: err.message, severity: SEVERITY.ERROR })
          );
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      dispatch(
        showSnackbar({
          message: 'Please enter the coupon code to update',
          severity: 'warning',
        })
      );
    }
  };

  return (
    <OtherLogoWrapper displaySubLogo={false}>
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="my-3">
              <div
                className="fw-bold font14px cursor-pointer color-blue roboto"
                onClick={handleManageAccount}
              >
                <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                &nbsp; Manage Your Account
              </div>
              <div className="text-center text-sm-start">
                <h3 className="mt-4 mb-4 kranto-bold">Redeem your code</h3>
              </div>

              <div className="form-member-group">
                <Label htmlFor="firstName">Code</Label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={code}
                  maxLength="30"
                  onChange={(e) => setCode(e.target.value)}
                  autoComplete="off"
                />
              </div>

              <div className="mt-4 text-center" style={{ margin: 'auto' }}>
                <button
                  onClick={handleSave}
                  type="button"
                  className="btn btn-primary px-5"
                  disabled={processing}
                  style={processing ? disable : active}
                  style={{
                    backgroundColor: '#2B8CB3',
                    width: '100%',
                    border: '1px solid #2B8CB3',
                  }}
                >
                  {processing ? <ButtonLoader /> : 'APPLY'}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </OtherLogoWrapper>
  );
};

export default UpdateCoupon;
