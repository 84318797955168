import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { hideErrorAlert } from '../../../redux/alert';
import { active, disable } from '../../../pages/login/style';
import { ButtonLoader } from '../../Loader';
import React, { useEffect } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { fetchPaymentSources } from '../../../redux/paymentSource';
import { useNavigate } from 'react-router-dom';

function FailedPaymentAlert(props) {
  const { openError } = useSelector((state) => state.alert);
  const { card, paypal } = useSelector((state) => state.paymentSourceDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => dispatch(hideErrorAlert());
  console.log(props);

  useEffect(() => {
    if (openError) {
      dispatch(fetchPaymentSources());
    }
  }, [openError]);

  const updateFailedPaymentMethod = () => {
    card.forEach((c) => {
      if (c?.primaryPaymentSource) {
        navigate('/update-card-payment-method', {
          state: {
            ...c,
          },
        });
      }
    });

    paypal.forEach((c) => {
      if (c?.primaryPaymentSource) {
        navigate('/add-paypal-payment-method', {
          state: {
            operationType: 'UPDATE',
            paymentSourceId: c?.paymentSourceId,
          },
        });
      }
    });
  };

  return (
    <>
      {openError && (
        <div
          class="alert mt-2"
          style={{ background: '#FFE9D9' }}
          role="alert"
          onClose={handleClose}
        >
          <div>
            <div
              className="font20px"
              style={{
                fontFamily: 'kranto-text-cond-bold',
                color: '#771505',
              }}
            >
              <div>
                <FaExclamationTriangle />
                &nbsp; Payment Failed
              </div>
            </div>
            <div className="roboto font16px my-2" style={{ color: '#BC4C2E' }}>
              We couldn't process your payment. Please update your payment
              details to avoid any interruptions to your subscription.
            </div>
          </div>
          <button
            onClick={props?.handleSubmit ?? updateFailedPaymentMethod}
            type="button"
            disabled={props.processing}
            className="btn btn-danger px-3 mt-1 font14px"
            style={props.processing ? disable : active}
            style={{
              backgroundColor: '#771505',
              border: '1px solid #771505',
            }}
          >
            {props.processing ? <ButtonLoader /> : 'UPDATE PAYMENT DETAILS'}
          </button>
        </div>
      )}
    </>
  );
}

export default FailedPaymentAlert;
