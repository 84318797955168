import React, { useEffect, useState } from 'react';

import brokenHeart from 'assets/images/broken_heart.svg';
import sadEmoji from 'assets/images/sad_emoji.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import upff from './../../assets/images/upff_logo.svg';
import gaithertv from './../../assets/images/gaither_tv_plus_logo.svg';
import minno from './../../assets/images/minno_logo.svg';
import { useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { cancelAllSubscription, fetchCartDetails } from '../../redux/cart';
import { hideGlobalLoader, showGlobalLoader } from '../../redux/loader';
import { showSnackbar } from '../../redux/snackbar';
import { active, disable } from '../login/style';
import { SEVERITY, SUBSCRIPTION_STATUS } from '../../utils/constant';
import ActionAlerts from '../../components/Alert';
import OtherLogoWrapper from '../../components/LogoWrapper/other';
import { getPlanImage, getRemainingDays } from '../../utils/functions';
import { useDispatch } from 'react-redux';
import { ButtonLoader } from '../../components/Loader';
import Pause from './pause';
import styled from 'styled-components';
import { hideAlert, showAlert } from '../../redux/alert';
import { claimMyOffer } from '../../redux/account';

export const Btn = styled.button`
  font-weight: 500;
  font-size: 16x;
  background: #f9971f;
  color: #ffffff;
`;

function Cancel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [showLayout, setShowLayout] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const [reason, setReason] = useState('');
  const [satisfactionLevel, setSatisfactionLevel] = useState('');
  const [dailyUsage, setDailyUsage] = useState('');
  const [remainingDays, setRemainingDays] = useState(0);
  const [showClaimOffer, setShowClaimOffer] = useState(false);
  const [existingPlans, setExistingPlans] = useState([]);
  const [displayPause, setDisplayPause] = useState(false);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };
  const handleSatisfactionLevel = (event) => {
    setSatisfactionLevel(event.target.value);
  };
  const handleDailyUsage = (event) => {
    setDailyUsage(event.target.value);
  };

  const displaySnackbar = (message, severity) =>
    dispatch(showSnackbar({ message, severity }));

  useEffect(() => {
    loadCartDetails();

    return () => {
      dispatch(hideAlert());
    };
  }, []);

  const checkForPause = (plans) => {
    let subs = plans.find(
      (p) =>
        p.status === SUBSCRIPTION_STATUS.IN_TRIAL ||
        p.status === SUBSCRIPTION_STATUS.NON_RENEWING
    );

    if (subs != null) return false;

    let activeSubs = plans.find((p) => p.status === SUBSCRIPTION_STATUS.ACTIVE);

    return activeSubs != null;
  };

  const loadCartDetails = async () => {
    await dispatch(showGlobalLoader());
    return await dispatch(fetchCartDetails())
      .then((response) => {
        let { existingPlans } = response;
        setExistingPlans(existingPlans);
        setDisplayPause(checkForPause(existingPlans));

        let formattedItems = [
          ...existingPlans
            .filter(
              (p) =>
                p.status === SUBSCRIPTION_STATUS.ACTIVE ||
                p.status === SUBSCRIPTION_STATUS.IN_TRIAL
            )
            .map((existingPlan) => {
              return {
                ...existingPlan,
                subscribed: true,
                checked:
                  existingPlan.status === SUBSCRIPTION_STATUS.ACTIVE ||
                  existingPlan.status === SUBSCRIPTION_STATUS.IN_TRIAL,
              };
            }),
        ];

        const days = getRemainingDays(existingPlans[0].nextBillingAt);

        setRemainingDays(days);
        setCartItems(formattedItems);
        setShowLayout(true);
        setShowClaimOffer(!response?.hasFreeMonth);
      })
      .catch((error) => {
        console.log(error);
        displaySnackbar(error.message, SEVERITY.ERROR);
      })
      .finally(() => {
        dispatch(hideGlobalLoader());
      });
  };

  const getSubscribedItems = () => {
    return cartItems.filter(
      (item) => item?.subscribed === true || item.checked === true
    );
  };

  const handleManageAccount = () => {
    navigate('/dashboard');
  };

  const handleCancel = () => {
    if (reason === '' || satisfactionLevel === '' || dailyUsage === '') {
      dispatch(
        showSnackbar({
          message: 'Please fill all feedback details to proceed cancel',
          severity: 'warning',
        })
      );
      return;
    }

    setProcessing(true);

    const payload = {
      reason,
      satisfactionLevel,
      dailyUsage,
    };

    dispatch(cancelAllSubscription(payload))
      .then(() => {
        dispatch(
          showSnackbar({
            message:
              'All your subscriptions will be cancel at the end of current term',
            severity: 'success',
          })
        );
        navigate('/dashboard');
      })
      .catch((err) => {
        console.log(err);
        dispatch(showSnackbar({ message: err.message, severity: 'error' }));
      })
      .finally(() => setProcessing(false));
  };

  const showClaimOfferAlert = () => {
    dispatch(
      showAlert({
        alertOperation: 'CLAIM_OFFER',
      })
    );

    navigate('/manage-subscription', {
      state: {
        alertOperation: 'CLAIM_OFFER',
      },
    });
  };

  const showCancelBanner = () => {
    dispatch(
      showAlert(
        `Sorry to see you go. You have selected to cancel the following subscriptions: ${getPlanList(existingPlans)}`
      )
    );
  };

  const getPlanList = (plans) => {
    plans = plans
      .filter((item) => item.status !== SUBSCRIPTION_STATUS.CANCELLED)
      .map((item) => item.name);
    console.log(plans);
    const formattedPlans =
      plans.length > 1
        ? plans.slice(0, -1).join(', ') + ', and ' + plans[plans.length - 1]
        : plans[0];

    return formattedPlans;
  };

  return (
    <OtherLogoWrapper displaySubLogo={false}>
      {showLayout && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="my-3">
                <div
                  className="fw-bold font14px cursor-pointer color-blue roboto"
                  onClick={handleManageAccount}
                >
                  <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                  &nbsp; Manage Your Account
                </div>
              </div>
              <div className="text-center text-sm-start">
                <h3 className="mt-4 mb-4 kranto-bold">Cancel Subscriptions</h3>
              </div>

              {showFeedback && (
                <>
                  <ActionAlerts />

                  {remainingDays >= 90
                    ? showClaimOffer && (
                        <div className="my-2">
                          <div className="container border-orange p-3 bg-white">
                            <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
                              <div className="text-center text-md-start">
                                <p className="roboto font20px fw-bold text-orange">
                                  As a valued subscriber, we would like to offer
                                  you one free month of{' '}
                                  {getPlanList(existingPlans)}
                                </p>
                              </div>

                              <Btn
                                type="button"
                                className="btn btn-orange font16px mt-2 mt-md-0"
                                onClick={showClaimOfferAlert}
                              >
                                Claim My Offer
                              </Btn>
                            </div>
                          </div>
                        </div>
                      )
                    : displayPause && <Pause />}
                </>
              )}

              {showFeedback ? (
                <div className="container">
                  <div className="row my-2">
                    <div className="container container-border p-3 bg-white">
                      <div className="d-flex justify-content-center">
                        <img
                          src={remainingDays > 90 ? brokenHeart : sadEmoji}
                          alt="logo"
                        />
                      </div>
                      <h1 className="kranto-bold text-center">
                        {remainingDays > 90
                          ? 'It breaks our heart to see you go!'
                          : 'We are sad to see you go!'}
                      </h1>
                      <p className="roboto my-2 text-center font16px">
                        Before you cancel, please let us know the reason you are
                        leaving. Every bit of feedback helps!
                      </p>
                      <div className="d-flex justify-content-center flex-column align-items-center">
                        <div className="text-start my-2">
                          <p className="fw-bold roboto font16px">
                            What is the primary reason for canceling your UPTV
                            subscription?
                          </p>
                          <form>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Content is not interesting"
                                checked={
                                  reason === 'Content is not interesting'
                                }
                                onChange={handleReasonChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language1"
                              >
                                Content is not interesting
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Found a better service"
                                checked={reason === 'Found a better service'}
                                onChange={handleReasonChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language2"
                              >
                                Found a better service
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Too expensive"
                                checked={reason === 'Too expensive'}
                                onChange={handleReasonChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language3"
                              >
                                Too expensive
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Technical Issues"
                                checked={reason === 'Technical Issues'}
                                onChange={handleReasonChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language4"
                              >
                                Technical Issues
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="No longer needed"
                                checked={reason === 'No longer needed'}
                                onChange={handleReasonChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language5"
                              >
                                No longer needed
                              </label>
                            </div>
                          </form>
                          <p className="fw-bold roboto font16px my-2 mt-5">
                            How satisfied were you with the content on UPTV?
                          </p>
                          <form>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Very satisfied"
                                checked={satisfactionLevel === 'Very satisfied'}
                                onChange={handleSatisfactionLevel}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language1"
                              >
                                Very satisfied
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Satisfied"
                                checked={satisfactionLevel === 'Satisfied'}
                                onChange={handleSatisfactionLevel}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language2"
                              >
                                Satisfied
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Neutral"
                                checked={satisfactionLevel === 'Neutral'}
                                onChange={handleSatisfactionLevel}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language3"
                              >
                                Neutral
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Dissatisfied"
                                checked={satisfactionLevel === 'Dissatisfied'}
                                onChange={handleSatisfactionLevel}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language4"
                              >
                                Dissatisfied
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Very dissatisfied"
                                checked={
                                  satisfactionLevel === 'Very dissatisfied'
                                }
                                onChange={handleSatisfactionLevel}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language5"
                              >
                                Very dissatisfied
                              </label>
                            </div>
                          </form>
                          <p className="fw-bold roboto font16px my-2 mt-5">
                            How often did you use UPTV?
                          </p>
                          <form>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Daily"
                                checked={dailyUsage === 'Daily'}
                                onChange={handleDailyUsage}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language1"
                              >
                                Daily
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Several times a week"
                                checked={dailyUsage === 'Several times a week'}
                                onChange={handleDailyUsage}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language2"
                              >
                                Several times a week
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Once a week"
                                checked={dailyUsage === 'Once a week'}
                                onChange={handleDailyUsage}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language3"
                              >
                                Once a week
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="A few times a month"
                                checked={dailyUsage === 'A few times a month'}
                                onChange={handleDailyUsage}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language4"
                              >
                                A few times a month
                              </label>
                            </div>
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                value="Rarely"
                                checked={dailyUsage === 'Rarely'}
                                onChange={handleDailyUsage}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="language5"
                              >
                                Rarely
                              </label>
                            </div>
                          </form>

                          <div className="text-center mt-5">
                            If you cancel today, you will still have access to
                            content <br /> until {cartItems[0].nextBillingAt}
                          </div>

                          <div
                            className="mt-3 text-center"
                            style={{ margin: 'auto' }}
                          >
                            <button
                              onClick={handleCancel}
                              type="button"
                              className="btn btn-primary px-5 "
                              disabled={processing}
                              style={processing ? disable : active}
                              style={{
                                backgroundColor: '#2B8CB3',
                                border: '1px solid #2B8CB3',
                              }}
                            >
                              {processing ? (
                                <ButtonLoader />
                              ) : (
                                'CANCEL SUBSCRIPTION(S)'
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="row my-2">
                    <div className="container container-border p-3 bg-white">
                      <div className="d-flex justify-content-between mb-3 mx-2 my-2">
                        <h4 className="kranto-bold">Your Subscriptions</h4>
                      </div>
                      <div className="container">
                        <table className="table table_custom">
                          <thead style={{ borderStyle: 'hidden' }}>
                            <tr>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {getSubscribedItems().map((plan, index) => {
                              return (
                                <tr style={{ height: '70px' }}>
                                  <td>
                                    <div className="d-flex justify-content-between flex-wrap gap-3">
                                      <div>
                                        <img
                                          src={getPlanImage(plan.id)}
                                          // width={120}
                                          alt={plan.name}
                                        />
                                      </div>
                                      <div className="roboto font15px">
                                        <b>{plan?.name}</b>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="mt-3 text-center"
                      style={{ margin: 'auto' }}
                    >
                      <button
                        onClick={() => {
                          setShowFeedback(true);
                          showCancelBanner();
                        }}
                        type="button"
                        className="btn btn-primary px-5 "
                        style={active}
                        style={{
                          backgroundColor: '#2B8CB3',
                          border: '1px solid #2B8CB3',
                        }}
                      >
                        CANCEL ALL SUBSCRIPTION(S)
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </OtherLogoWrapper>
  );
}

export default Cancel;
