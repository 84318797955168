import React from 'react';
import logo from './../../assets/images/black_logo.png';
import upff from './../../assets/images/upff_logo.svg';
import gaithertv from './../../assets/images/gaither_tv_plus_logo.svg';
import minno from './../../assets/images/minno_logo.svg';
import profile from './../../assets/images/profile.svg';
import caretsvg from './../../assets/images/caret-down.svg';
import { useNavigate } from 'react-router-dom';
import localStore from 'utils/functions/localstore';

const Header = ({ displaySubLogo = true }) => {
  const navigate = useNavigate();
  const isUserLoggedIn = localStore.get('token') != null;

  const handleManageSubscription = () => {
    navigate('/manage-subscription');
  };

  const handleManagePaymentMethod = () => {
    navigate('/manage-payment-method');
  };

  const handleEmailPreferences = () => {
    navigate('/emailPreferences');
  };

  const handleLogout = () => {
    localStore.remove('token');
    navigate('/');
  };

  return (
    <header className="mt-2">
      <div className="d-flex flex-column align-items-center position-relative">
        <div className="d-flex">
          <img src={logo} width={100} />
        </div>

        {isUserLoggedIn && (
          <div class="dropdown user-profile-btn" id="user-profile-menu">
            <button
              src={profile}
              class="btn-transparent"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="d-flex" style={{ gap: '2px' }}>
                <img src={profile} className="d-inline" />
                <img src={caretsvg} className="d-inline" />
              </div>
            </button>

            <ul class="dropdown-menu roboto font14px">
              <li>
                <a
                  class="dropdown-item"
                  onClick={handleManageSubscription}
                  href="#"
                >
                  Your Subscriptions
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>

              <li>
                <a class="dropdown-item" onClick={handleManagePaymentMethod}>
                  Payment Methods
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>

              <li>
                <a class="dropdown-item" onClick={handleEmailPreferences}>
                  Email Preferences
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>

              <li>
                <a class="dropdown-item" onClick={handleLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        )}

        {displaySubLogo && (
          <div className="d-none d-sm-block">
            <div
              className="d-flex justify-content-center m-3"
              style={{ gap: '20px' }}
            >
              <img src={upff} width={110} />
              <img src={gaithertv} width={130} />
              <img src={minno} width={110} />
            </div>
          </div>
        )}
      </div>
      <div className="border-bottom-orange vw-100 mt-2" />
    </header>
  );
};

export default Header;
