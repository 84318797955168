import React, { useState, useEffect, useRef } from 'react';
import { Heading } from './style';

import { useNavigate } from 'react-router-dom';

import CBCardComponent from './../../components/cardComponent/';
import { loadChargebee } from './../../redux/chargebee';
import { useDispatch } from 'react-redux';
import { addCardPaymentSources } from './../../redux/paymentSource';
import SnackbarAlert from 'components/Snackbar';
import { showSnackbar } from './../../redux/snackbar';
import OtherLogoWrapper from 'components/LogoWrapper/other';

function AddCardPaymentMethod() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState(null);
  const [zip, setZip] = useState(null);
  const [state, setState] = useState(null);

  const handleManageAccount = () => {
    navigate('/dashboard');
  };
  const cardRef = useRef(null);

  return (
    <>
      <OtherLogoWrapper displaySubLogo={false}>
        <div className="p-3">
          <div className="pm-layout">
            <div onClick={handleManageAccount}>
              <b>
                <a className="link-opacity-80 text-decoration-none" href="#">
                  <span className="font17px text-center align-content-center">
                    &#8592;
                  </span>{' '}
                  Manage Your Account
                </a>
              </b>
            </div>

            <div className="">
              <div>
                <Heading className="text-center text-sm-start mt-3 mb-2">
                  Add Payment Method
                </Heading>
              </div>

              <div className="my-4">
                <CBCardComponent ref={cardRef} operationType="ADD" />
              </div>
            </div>
          </div>
        </div>
      </OtherLogoWrapper>
    </>
  );
}

export default AddCardPaymentMethod;
