import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalLoader: false,
};

export const loader = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showGlobalLoader: (state) => {
      state.globalLoader = true;
    },
    hideGlobalLoader: (state, action) => {
      state.globalLoader = false;
    },
  },
});

export const { showGlobalLoader, hideGlobalLoader } = loader.actions;

export default loader.reducer;
