import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ProgressBar from 'components/ProgressBar';
import SnackbarAlert from 'components/Snackbar';
import ProtectedRoute from './protectedRoute';

import lazyWithFallback from 'utils/lazyWithFallback';
import { useSelector } from 'react-redux';
import Summary from '../pages/summary';
import AdjustSubscription from '../pages/manageSubscription/adjustSubscription';
import Cancel from '../pages/cancel';
import ManagePaymentMethod from 'pages/managePaymentMethod';
import AddPaymentMethod from 'pages/managePaymentMethod/addPaymentMethod';
import AddCardPaymentMethod from 'pages/managePaymentMethod/addCardPaymentMethod';
import AddPaymentMethodSuccess from 'pages/managePaymentMethod/addPaymentMethodSuccess';
import AddPaypalPaymentMethod from 'pages/managePaymentMethod/addPaypalPaymentMethod';
import UpdateCardPaymentMethod from 'pages/managePaymentMethod/updateCardPaymentMethod';
import CommunicationPreferences from '../pages/emailPreferences';
import ConfirmPreferences from '../pages/emailPreferences/confirm';
import UpdateCoupon from '../pages/updateCoupon';

const Login = lazyWithFallback(() => import('pages/login'));
const Dashboard = lazyWithFallback(() => import('pages/dashboard'));
const ManageSubscription = lazyWithFallback(
  () => import('pages/manageSubscription')
);

const App = () => {
  const { globalLoader } = useSelector((state) => state.loader);
  return (
    <>
      {globalLoader && <ProgressBar />}
      <Router>
        <Suspense fallback={<ProgressBar />}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route
                exact
                path="/manage-subscription"
                element={<ManageSubscription />}
              />
              <Route
                exact
                path="/manage-subscription/upgrade"
                element={<AdjustSubscription />}
              />
              <Route
                exact
                path="/manage-subscription/downgrade"
                element={<AdjustSubscription />}
              />
              <Route exact path="/cancelAll" element={<Cancel />} />
              <Route exact path="/coupon" element={<UpdateCoupon />} />
              <Route exact path="/summary" element={<Summary />} />

              <Route
                exact
                path="/emailPreferences"
                element={<CommunicationPreferences />}
              />
              <Route
                exact
                path="/emailConfirm"
                element={<ConfirmPreferences />}
              />
              <Route
                exact
                path="/manage-payment-method"
                element={<ManagePaymentMethod />}
              />
              <Route
                exact
                path="/add-payment-method"
                element={<AddPaymentMethod />}
              />
              <Route
                exact
                path="/add-card-payment-method"
                element={<AddCardPaymentMethod />}
              />
              <Route
                exact
                path="/add-paypal-payment-method"
                element={<AddPaypalPaymentMethod />}
              />
              <Route
                exact
                path="/update-card-payment-method"
                element={<UpdateCardPaymentMethod />}
              />
              <Route
                exact
                path="/add-payment-method-success"
                element={<AddPaymentMethodSuccess />}
              />
            </Route>
            <Route path="*" element={<Login />} />
          </Routes>
        </Suspense>
      </Router>
      <SnackbarAlert />
    </>
  );
};

export default App;
