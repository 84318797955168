import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './redux';
import { getPersistConfig } from 'redux-deep-persist';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = getPersistConfig({
  key: 'root',
  storage: storageSession,
  whitelist: [],
  rootReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

export const persistor = persistStore(store);
