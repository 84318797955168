import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import localStore from 'utils/functions/localstore';

const ProtectedRoute = () => {
  const token = localStore.get('token');
  if (token) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
