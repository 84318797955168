import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from '@chargebee/chargebee-js-react-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from './../../redux/snackbar';
import { addCardPaymentSources } from './../../redux/paymentSource';
import { useLocation, useNavigate } from 'react-router-dom';
import { active, disable } from 'pages/login/style';
import { ButtonLoader } from 'components/Loader';
import { initChargebee, loadChargebee } from './../../redux/chargebee';

const CBCardComponent = (props, ref) => {
  const cardRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const card = location?.state;

  const name_ = createFullName(card?.firstName, card?.lastName);

  const [name, setName] = useState(name_);
  const [zip, setZip] = useState(card?.billingZip);
  const [state, setState] = useState(card?.billingState);

  const { isCbInitiated } = useSelector((state) => state.chargebee);

  const [token, setToken] = useState('');
  const [processing, setProcessing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState({
    classes: {
      focus: 'focus',
      invalid: 'invalid',
      empty: 'empty',
      complete: 'complete',
    },
    style: {
      base: {
        color: '#2a2d5b',
        fontWeight: '500',
        fontFamily: 'filson-pro-regular, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':focus': {},
        '::placeholder': {
          color: '#9293AB',
          fontSize: '14px',
        },
        ':focus::placeholder': {
          color: '#666',
        },
        ':-webkit-autofill': {
          webkitTextColor: '#2a2d5b',
        },
      },
      invalid: {
        color: '#e41029',
        ':focus': {
          color: '#e44d5f',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    },
    locale: 'en',
  });

  useEffect(() => {
    if (!isCbInitiated) {
      dispatch(initChargebee());
    }
  }, []);

  const tokenize = (e, additionalData) => {
    return new Promise((resolve, reject) => {
      if (e) e.preventDefault();
      cardRef.current
        .tokenize(additionalData)
        .then((data) => {
          setToken(data.token);
          setErrorMessage('');
          resolve(data.token); // Resolve the Promise with the token
        })
        .catch((error) => {
          setTimeout(() => {
            dispatch(
              showSnackbar({ message: error.message, severity: 'error' })
            );
          }, 100);

          setToken('');
          setErrorMessage(error.message);
          // setErrorMessage("Problem while tokenizing your card details");
          reject(error); // Reject the Promise with the error
        });
    });
  };

  function createFullName(firstName, lastName) {
    let parts = [];

    if (firstName) parts.push(firstName.trim());

    if (lastName) parts.push(lastName.trim());

    return parts.join(' ');
  }

  function getExpiry() {
    if (card?.expiryYear) {
      return `${card?.expiryMonth}/${card?.expiryYear?.toString()?.slice(-2)}`;
    }
  }

  const getOperationName = () => {
    let operationType = props?.operationType;
    if (operationType === 'ADD') return 'Add Payment Method';
    else if (operationType === 'UPDATE') return 'Update Payment Method';
  };

  const handlePM = async (event) => {
    try {
      setProcessing(true);
      let firstName = name,
        lastName = null;

      if (name != null) {
        let name_ = name.trim().split(/\s+/);
        if (name_.length >= 2) {
          firstName = name_[0]; // Join all but the last element
          lastName = name_.slice(1).join(' ');
        }
      }

      let token = await tokenize(event, { firstName, lastName, zip, state });

      dispatch(
        addCardPaymentSources({
          token,
          operationType: props?.operationType,
          paymentsource: 'CARD',
          paymentSourceId: card?.paymentSourceId,
        })
      )
        .then((response) => {
          let card_ = response?.card;
          if (card_ != null && card_.length > 0) card_ = card_[0];
          navigate('/add-payment-method-success', {
            state: {
              type: 'card',
              operationType: props?.operationType,
              card: card_,
            },
          });
        })
        .catch((error) => {
          setProcessing(false);
          dispatch(
            showSnackbar({ message: error?.message, severity: 'error' })
          );
        });
    } catch (error) {
      setProcessing(false);
      dispatch(showSnackbar({ message: error?.error_msg, severity: 'error' }));
    }
  };

  useImperativeHandle(ref, () => ({
    tokenize,
    handlePM,
  }));

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFirstName(newValue);
  };

  const setLocale = (e) => {
    e.preventDefault();
    const locale = e.target.id;
    const updatedOptions = { ...options, locale };
    setOptions(updatedOptions);
  };

  const onChange = (event) => {
    const updatedErrors = { ...errors };
    let newErrorMessage = '';

    if (event.error) {
      updatedErrors[event.field] = event.error;
      newErrorMessage = event.error.message;
    } else {
      updatedErrors[event.field] = null;
      const filteredErrors = Object.values(updatedErrors).filter((val) => val);
      const errorObj = filteredErrors.pop();

      if (errorObj) newErrorMessage = errorObj.message;
      else newErrorMessage = '';
    }

    setErrors(updatedErrors);
    setErrorMessage(newErrorMessage);
  };

  const { style, classes, locale, fonts } = options;

  return (
    <>
      {isCbInitiated && (
        <>
          <form
            onSubmit={handlePM}
            className="needs-validation"
            noValidate={false}
            id="handle-pm"
          >
            <div class="mb-3">
              <label for="name" class="form-label roboto font16px">
                Name on Card
              </label>
              <input
                class="form-control"
                id="name"
                onChange={(e) => setName(e.target.value)}
                defaultValue={name}
                required
              />
            </div>
            <CardComponent
              ref={cardRef}
              styles={style}
              classes={classes}
              className="fieldset field"
              locale={locale}
              fonts={fonts}
              onChange={onChange}
            >
              <div class="mb-3">
                <label for="ccnumber" class="form-label roboto font16px">
                  Credit Card Number
                </label>
                <CardNumber
                  placeholder={card?.maskedNumber}
                  required
                  className="form-control h40px"
                />
              </div>

              <div className="row">
                <div className="col-6">
                  <div class="mb-3">
                    <label for="expiration" class="form-label roboto font16px">
                      Expiration
                    </label>
                    <CardExpiry
                      placeholder={getExpiry()}
                      className="form-control h40px"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div class="mb-3">
                    <label for="cvv" class="form-label">
                      CVC/CVV
                    </label>
                    <CardCVV className="form-control h40px" />
                  </div>
                </div>
              </div>
            </CardComponent>

            <div className="row">
              <div className="col-6">
                <div class="mb-3">
                  <label for="state" class="form-label roboto font16px">
                    State
                  </label>
                  <input
                    class="form-control"
                    id="state"
                    onChange={(e) => setState(e.target.value)}
                    defaultValue={state}
                    required
                  />
                </div>
              </div>
              <div className="col-6">
                <div class="mb-3">
                  <label for="zip" class="form-label roboto font16px">
                    Zip
                  </label>
                  <input
                    class="form-control"
                    id="zip"
                    onChange={(e) => setZip(e.target.value)}
                    defaultValue={zip}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="text-center mt-5">
              {/* <button className="add-pm-btn w-100">Add Payment Method</button> */}

              <button
                // type="button"
                className="btn btn-primary px-5 "
                disabled={processing}
                style={processing ? disable : active}
                style={{ backgroundColor: '#2B8CB3' }}
              >
                {processing ? <ButtonLoader /> : getOperationName()}
              </button>
            </div>
          </form>
        </>
      )}

      {/* {errorMessage && (
        <div className="text-danger text-center" role="alert">
          {errorMessage}
        </div>
      )} */}
    </>
  );
};

export default forwardRef(CBCardComponent);
