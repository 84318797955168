import { createSlice } from '@reduxjs/toolkit';
import { UP_ENGINE } from 'config';
import { api } from 'utils/functions/api';

const initialState = {
  card: [],
  paypal: [],
};

export const paymentSourceDetails = createSlice({
  name: 'paymentSourceDetails',
  initialState,
  reducers: {
    setPaymentSourceList: (state, action) => {
      let { card, paypal } = action.payload;
      state.card = card;
      state.paypal = paypal;
    },
  },
});

export const { setPaymentSourceList } = paymentSourceDetails.actions;

export default paymentSourceDetails.reducer;

export const fetchPaymentSources = () => (dispatch) =>
  api.get(UP_ENGINE, '/api/payments/').then((response) => {
    dispatch(setPaymentSourceList(response));
    return response;
  });

export const addCardPaymentSources = (payload) => (dispatch) =>
  api
    .post(UP_ENGINE, '/api/payments/updatePaymentSources', payload)
    .then((response) => {
      dispatch(setPaymentSourceList(response));
      return response;
    });

export const getpaymentToken = async () => {
  return api.post(UP_ENGINE, '/api/payments/paymentToken', {});
};
