import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  openError: false,
  message: '',
  callback: null,
  alertOperation: null,
};

export const alert = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.open = true;
      state.message = action.payload?.message ?? action.payload;
      state.callback = action.payload?.callback;
      state.alertOperation = action.payload?.alertOperation;
    },
    hideAlert: (state) => {
      state.open = false;
      state.message = '';
      state.callback = null;
    },
    showErrorAlert: (state, action) => {
      state.openError = true;
    },
    hideErrorAlert: (state) => {
      state.openError = false;
    },
  },
});

export const { showAlert, hideAlert, showErrorAlert, hideErrorAlert } =
  alert.actions;

export default alert.reducer;
